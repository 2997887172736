{
  "daytime": {
    "earlier": "Plus tôt",
    "later": "Plus tard"
  },
  "login": {
    "title": "Se connecter",
    "text": "Connectez-vous à votre compte",
    "button": "Se connecter",
    "stressLevel": "Niveau de stress",
    "errorDialog": {
      "message": "Identifiant ou mot de passe incorrect",
      "accept": "Ok"
    },
    "placeholder": {
      "username": "Veuillez entrer votre identifiant",
      "password": "Veuillez saisir votre mot de passe"
    }
  },
  "sidebar": {
    "bulletins": "Avalanche.report",
    "dangerSources": "Sources de danger",
    "observations": "Observations",
    "qfa": "QFA",
    "modelling": "Modélisation",
    "modellingForecast": "Prévisions",
    "modellingZamg": "ZAMG multi-modèle",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "GeoSphere météogramme",
    "modellingSnowpack": "SNOWPACK model",
    "modellingSnowpackMeteo": "SNOWPACK modelled meteo",
    "weatherbox": "Boîte météo",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Se déconnecter",
    "language": "Langue",
    "en": "Anglais",
    "de": "Allemand",
    "fr": "Français",
    "it": "Italien",
    "es": "Espagnol",
    "ca": "Catalan",
    "oc": "Aranais",
    "fullscreen": "Plein écran",
    "settings": "Paramètres",
    "statistics": "Statistiques",
    "admin": "Admin",
    "region": "Région de prévision",
    "education": "Pédagogie"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Stabilité du manteau neigeux",
      "frequency": "Fréquence",
      "avalancheSize": "Taille d'avalanche",
      "dangerRating": "Degré de danger ",
      "override": "(remplacer degré de danger)",
      "matrix": "Matrice",
      "referToFairStability": "Voir stabilité moyenne",
      "referToPoorStability": "Voir stabilité faible",
      "dangerLevel1": "Indice de risque 1 (Faible)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "manquant",
      "available": "disponible",
      "forecast": "Prévisions",
      "analysis": "analyse",
      "loading": "Chargement des sources de danger...",
      "saveError": "Impossible de sauvegarder la source de danger !",
      "loadDangerSourcesError": "Impossible de charger les sources de danger !",
      "loadVariantsError": "Impossible d'éditer ce type de source."
    },
    "variantStatus": {
      "active": "actif",
      "dormant": "dormant",
      "inactive": "inactif"
    },
    "tooltip": {
      "read": "Lire sources de danger",
      "edit": "Éditer sources de danger"
    },
    "table": {
      "loadMore": "Charger {{count}} jours de plus",
      "title": {
        "forecast": "Prévisions",
        "analysis": "Analyse"
      }
    },
    "create": {
      "variantStatus": "Statut",
      "variantEditable": "Ce type de source peut être édité.",
      "variantNotEditable": "Impossible d'éditer ce type de source.",
      "noVariantsForSelectedDay": "Pas de type de source défini pour cette date.",
      "tooltip": {
        "editDangerSource": "Éditer source de danger",
        "loadVariants": "Charger les types de source de la veille",
        "edit": "Éditer les microrégions",
        "copy": "Copier type de source",
        "delete": "Effacer type de source",
        "compare": "Comparer type de source",
        "forecast": "Comparer avec les prévisions"
      },
      "createDangerSource": "Créer source de danger",
      "createVariant": "Créer nouveau type de source",
      "noDangerSources": "Aucune source de danger",
      "title": {
        "avalanche": "Avalanche",
        "matrix": "Matrice",
        "characteristics": "Caractéristiques",
        "comment": "Commentaire"
      },
      "label": {
        "glidingSnowActivity": "Activité de neige glissante",
        "snowHeight": "Hauteur de neige",
        "zeroDegreeIsotherm": "Isotherme 0 °C",
        "looseSnowMoisture": "Humidité de la neige meuble",
        "looseSnowGrainShape": "Forme des grains de neige meuble",
        "slabGrainShape": "Forme des grains de neige de la plaque",
        "slabThickness": "Épaisseur de la plaque",
        "slabHandHardness": "Dureté de la plaque au test manuel",
        "slabHardnessProfile": "Dureté de la plaque",
        "slabEnergyTransferPotential": "Potentiel de transfert d'énergie de la plaque",
        "slabDistribution": "Répartition de la plaque",
        "weakLayerGrainShape": "Forme des grains de la couche fragile",
        "weakLayerGrainSize": "Taille des grains de la couche fragile",
        "weakLayerPersistent": "Persistance de la couche fragile",
        "weakLayerThickness": "Épaisseur de la couche fragile",
        "weakLayerStrength": "Résistance de la couche fragile",
        "weakLayerWet": "Humidité de la couche fragile",
        "weakLayerCrustAbove": "Croûte sur la couche fragile",
        "weakLayerCrustBelow": "Croûte sous la couche fragile",
        "weakLayerPosition": "Position de la couche fragile",
        "weakLayerCreation": "Formation de la couche fragile",
        "weakLayerDistribution": "Répartition de la couche fragile",
        "dangerSpotRecognizability": "Reconnaître les endroits dangereux",
        "remoteTriggering": "Déclenchement à distance",
        "hasDaytimeDependency": "Selon le moment de la journée",
        "dangerIncreaseWithElevation": "Augmentation du danger avec l'altitude",
        "runoutIntoGreen": "Zone de dépôt hors neige",
        "highestDangerAspect": "Risque le plus élevé",
        "dangerPeak": "Pic de danger",
        "slopeGradient": "Pente",
        "naturalRelease": "Avalanches spontanées",
        "terrainTypes": "Types de terrain",
        "dangerSigns": "Signes de danger",
        "aspects": "Expositions"
      },
      "editVariantDialog": {
        "save": "Sauvegarder",
        "cancel": "Annuler"
      },
      "deleteVariantDialog": {
        "message": "Effacer ce type de source ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "noRegionDialog": {
        "message": "Vous devez avoir au moins une microrégion.",
        "accept": "Ok"
      },
      "discardDialog": {
        "message": "Abandonner les modifications ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "saveErrorDialog": {
        "message": "Erreur en sauvegardant les sources de dangers !",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Charger tous les types de sources de danger de la veille ?",
        "accept": "Oui",
        "reject": "Non"
      }
    }
  },
  "avalancheType": {
    "title": "Type d'avalanche",
    "label": {
      "slab": "PLAQUE DE NEIGE",
      "loose": "NEIGE MEUBLE",
      "glide": "NEIGE GLISSANTE"
    },
    "tooltip": {
      "slab": "Avalanche de plaque",
      "loose": "Avalanche de neige meuble",
      "glide": "Avalanche de glissement"
    }
  },
  "elevation": {
    "title": "Altitude",
    "high": "Au-dessous",
    "low": "Au-dessus",
    "treeline": "Limite de la forêt"
  },
  "bulletins": {
    "title": "Avalanche.report",
    "tooltip": {
      "edit": "Éditer le bulletin",
      "read": "Lire le bulletin",
      "copy": "Copier le bulletin",
      "paste": "Coller le bulletin",
      "cancel": "Annuler la copie",
      "update": "Editer",
      "submit": "Envoyer",
      "submitUpdate": "Envoyer la mise à jour",
      "publishChange": "Publication directe (pas de messages)",
      "publishUpdate": "Publication directe",
      "caaml": "Afficher CAAML",
      "json": "Afficher JSON",
      "create": "Créer le bulletin",
      "preview": "Aperçu du bulletin (PDF)",
      "check": "Vérifier le bulletin",
      "info": "Afficher l'état de la publication",
      "publishAll": "Publier toutes les régions de prévision",
      "mediaFile": "Télécharger un fichier média"
    },
    "status": {
      "submittedInfo": "Publication prévue à 17h00",
      "resubmittedInfo": "Publication prévue à 08h00",
      "publishedInfo": "Publié",
      "noPublicationInfo": "PAS de publication automatique",
      "loadingPreview": "Créer un aperçu PDF",
      "loading": "Chargement du bulletin...",
      "loadMoreBulletins": "Charger {{count}} autres bulletins",
      "publishing": "Bulletin en cours de publication...",
      "submitting": "Bulletin en cours d'envoi...",
      "saveError": "Impossible de sauvegarder le bulletin !",
      "loadBulletinsError": "Impossible de charger les bulletins !"
    },
    "create": {
      "tooltip": {
        "edit": "Éditer les microrégions",
        "copy": "Copier la région de prévision",
        "delete": "Effacer la région de prévision",
        "undo": "Annuler dernière modification dans région de prévision",
        "redo": "Rétablir dernière modification dans région de prévision",
        "createRegion": "Créer région de prévision",
        "cancel": "Annuler",
        "acceptSuggestion": "Accepter les régions proposées",
        "rejectSuggestion": "Refuser les régions proposées",
        "saveChanges": "Sauvegarder les modifications",
        "discardChanges": "Abandonner les modifications",
        "incompleteAvalancheProblem": "Situation avalancheuse typique incomplète",
        "saveBulletin": "Enregistrer le bulletin",
        "discardBulletin": "Abandonner les modifications du bulletin",
        "loadBulletin": "Téléchargement du bulletin de la veille",
        "uploadJsonBulletin": "Mettre en ligne au format JSON",
        "downloadJsonBulletin": "Télécharger au format JSON",
        "daytimeDependency": "Selon le moment de la journée",
        "treeline": "Limite de la forêt",
        "tendency": {
          "decreasing": "Le danger d'avalanche diminue",
          "steady": "Le danger d'avalanche reste inchangé",
          "increasing": "Le danger d'avalanche augmente",
          "danger": "Danger d'avalanche"
        },
        "textcat": {
          "edit": "Éditer",
          "copy": "Copier",
          "paste": "Coller",
          "example": "Télécharger exemple de texte",
          "delete": "Effacer"
        },
        "moveUpAvalancheProblem": "Déplacer situation avalancheuse typique en haut",
        "moveDownAvalancheProblem": "Déplacer situation avalancheuse typique en bas",
        "dangerRatingDirectionUp": "Vers le haut",
        "dangerRatingDirectionDown": "Vers le bas"
      },
      "save": "Sauvegarder",
      "discard": "Abandonner",
      "back": "Retour",
      "createAggregatedRegion": "Nouvelle région de prévision",
      "pasteAggregatedRegion": "Coller région de prévision",
      "pendingSuggestions": "Suggestions",
      "rejectSuggestion": "Refuser",
      "acceptSuggestion": "Accepter",
      "cancelCopyBulletin": "Annuler",
      "createAvalancheProblem": "Situation avalancheuse typique nouvelle",
      "ownRegions": "Mes régions de prévision",
      "foreignRegions": "Régions étrangères",
      "externalRegions": "Régions externes",
      "mapSelectRegion": "Sélectionner des régions sur la carte.",
      "statusHeader": "Statut du bulletin",
      "suggestions": "Suggestions",
      "noBulletinsForSelectedDay": "Pas de région définie pour cette date.",
      "autosave": "Sauvegardé automatiquement",
      "regionEditable": "Cette région de prévision peut être éditée.",
      "regionNotEditable": "Impossible d'éditer cette région de prévision.",
      "regionLocked": "Cette région de prévision est verrouillée.",
      "changeDangerRatingElevation": "Extension de l'indice de risque",
      "mapTop": "Carte au-dessus",
      "mapLeft": "Carte sur le côté",
      "showNotes": "Afficher les notes",
      "hideNotes": "Masquer les notes",
      "incompleteAvalancheProblem": "Incomplet",
      "label": {
        "importantObservations": "Observations importantes",
        "avalancheProblems": "Situation avalancheuse typique",
        "aspects": "Expositions",
        "highlights": "Vigilance particulière ",
        "avActivityHighlights": "Titre situation avalancheuse",
        "avActivityComment": "Description du danger d'avalanche",
        "snowpackStructureComment": "Description structure du manteau neigeux",
        "dangerPatterns": "Modèle de risques",
        "tendencyComment": "Description de la tendance",
        "notes": "Notes"
      },
      "copyRegion": {
        "label": "Copier la région de prévision dans un autre bulletin",
        "select": "Copier dans...",
        "currentBulletin": "Ce bulletin"
      },
      "title": {
        "avalancheProblem": "Situations avalancheuses typiques",
        "dangerDescription": "Description du danger d'avalanche",
        "forenoon": "Matin",
        "afternoon": "Après-midi",
        "snowpackStructure": "Structure du manteau neigeux",
        "tendency": "Tendance",
        "showTranslations": "Afficher traductions",
        "hideTranslations": "Masquer traductions"
      },
      "placeholder": {
        "notes": "Écrivez ici ..."
      },
      "pmDialog": {
        "noIFrameSupport": "Votre navigateur ne supporte pas les IFrames !"
      },
      "discardDialog": {
        "message": "Abandonner les modifications ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "deleteAggregatedRegionDialog": {
        "message": "Effacer cette région ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "noRegionDialog": {
        "message": "Vous devez avoir au moins une microrégion.",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Télécharger les bulletins de la veille ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "loadAutoSaveDialog": {
        "message": "Télécharger le bulletin sauvegardé automatiquement ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "saveErrorDialog": {
        "message": "Erreur en sauvegardant les bulletins !",
        "accept": "Ok"
      },
      "changeErrorDialog": {
        "message": "Erreur lors de la modification des bulletins !",
        "accept": "Ok"
      },
      "loadingErrorDialog": {
        "message": "Erreur lors du téléchargement des bulletins !",
        "accept": "Ok"
      },
      "loadingJsonFileErrorDialog": {
        "message": "Erreur de chargement des bulletins du fichier JSON",
        "accept": "Ok"
      },
      "avalancheProblemErrorDialog": {
        "message": "Problème d'avalanche, expositions, stabilité du manteau neigeux, fréquence et/ou taille de l'avalanche manquent pour au moins un problème d'avalanche.",
        "accept": "Ok"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "Pour quelle situation souhaitez-vous charger un exemple de texte",
        "newSnow": "Neige fraîche",
        "windSlab": "Plaque à vent",
        "persistentWeakLayers": "Neige ancienne (Sous-couche fragile persistante)",
        "wetSnow": "Avalanches mouillées (Neige humide)",
        "glidingSnow": "Avalanches de glissement (Avalanches de fond)",
        "favourableSituation": "Situation favorable",
        "cancel": "Annuler"
      },
      "removeDaytimeDependencyDialog": {
        "message": "Quelles sont les situations avalancheuses que vous souhaitez conserver ?",
        "cancel": "Annuler"
      },
      "strategicMindsetDialog": {
        "title": "États d'esprit stratégiques",
        "introduction": "L'« état d'esprit stratégique » a pour but d'exprimer notre attitude mentale à l'égard de la situation avalancheuse et de son évolution dans la région de prévision correspondante. Il ne doit pas découler directement de l'évaluation des dangers, mais plutôt être compris et utilisé comme une impression intuitive de la situation globale. Les « états d'esprit stratégiques » sont donc avant tout un outil de communication. En les utilisant ainsi que les définitions associées, nous, prévisionnistes, pouvons échanger rapidement et efficacement des informations sur l'évolution de la situation de danger et trouver un langage commun pour la communication.",
        "description": {
          "title": "Description :",
          "text": {
            "assessment": "L'évaluation du danger est <b>très incertaine</b> en raison d'<b>informations insuffisantes</b> provenant du terrain.",
            "stepping_out": "<b>Tendance à la baisse</b> du danger d'avalanche (y compris à l'intérieur d'un indice de danger).",
            "status_quo": "Même situation de danger. <b>Pas de changement significatif</b> par rapport à la veille.",
            "stepping_back": "<b>Tendance à l'augmentation</b> du danger d'avalanche (y compris à l'intérieur d'un indice de danger).",
            "entrenchment": "<b>Situation avalancheuse presque constante et tendue.</b> Le principal danger provient d'une ou plusieurs <b>couches faibles persistantes</b> susceptibles de se déclencher.",
            "free_ride": "<b>Faible risque d'avalanche</b> avec une couverture neigeuse généralement stable. Seules de petites avalanches sont possibles sur les terrains extrêmement escarpés.",
            "high_alert": "Situation avalancheuse exceptionnelle avec un <b>indice de risque fort à très fort</b>. Des avalanches spontanées <b>très grandes ou d'ampleur exceptionnelle</b> sont possibles.",
            "spring_diurnal": "Des avalanches de neige mouillée durant la journée qui sont soumises à un <b>net réchauffement durant la journée</b> sont décisives pour l'évaluation des risques."
          }
        },
        "communication": {
          "title": "Communication :",
          "text": {
            "assessment": "La situation avalancheuse doit être évaluée sur place. Sortie déconseillée.",
            "stepping_out": "Diminution progressive du danger. Toutefois, la prudence reste de mise et il convient d'évaluer localement l'amélioration de la situation avant de s'engager sur un terrain plus risqué. ",
            "status_quo": "La situation de danger reste inchangée. Les retours d'observateurs permet de mieux définir la situation de danger. Dans la communication, il faudrait mettre en avant les terrains plus favorables. ",
            "stepping_back": "Communication claire de l'augmentation du danger d'avalanche - y compris dans un même indice de risque. Communiquer sur les causes garantit une meilleure compréhension. ",
            "entrenchment": "Prudence et vigilance s'imposent. La situation n'évolue guère. Si possible, fournir des informations aussi précises que possible sur la distribution et un possible déclenchement. Intensifier la communication des observations illustrant la situation pour susciter la vigilance.",
            "free_ride": "Peu de texte. Souligner la situation générale favorable et les zones de danger potentiellement isolées.",
            "high_alert": "Communication sur tous les canaux. L'accent n'est plus mis sur les sports d'hiver. Le danger existe même dans des zones qui, habituellement, ne sont pas exposées au risque d'avalanche.",
            "spring_diurnal": "Situation favorable le matin, s'accentuant durant la journée. L'évaluation doit se faire localement en observant le ramollissement de la croûte de regel et la saturation croissante en eau du manteau neigeux."
          }
        },
        "examples": {
          "title": "Exemples :",
          "text": {
            "assessment": "Début de saison.",
            "stepping_out": "Le refroidissement entraîne une diminution du risque d'avalanches de neige mouillée après une phase de temps chaud et humide.",
            "status_quo": "Problème constant de plaques à vent avec des températures froides, de la neige meuble et du vent.",
            "stepping_back": "L'abondance de neige fraîche entraîne une augmentation des situations avalancheuses.",
            "entrenchment": "Problème sérieux de couches faibles persistantes avec risque d'avalanches de grande ampleur. La situation ne se stabilise que très lentement et nécessite une grande vigilance.",
            "free_ride": "Faible danger d'avalanche, pas de couches faibles persistantes, tous les terrains ont déjà été damés.",
            "high_alert": "Indice de danger 4 ou plus en raison de grandes quantités de neige fraîche et/ou de couches faibles problématiques et persistantes. ",
            "spring_diurnal": "Situation printanière avec un temps anticyclonique sec et des températures modérées ainsi qu'un manteau neigeux ancien généralement stable."
          }
        },
        "strategicMindset": {
          "assessment": "Grande incertitude",
          "stepping_out": "En cours d'amélioration",
          "status_quo": "Statu quo",
          "stepping_back": "En cours de détérioration",
          "entrenchment": "Persistante instable",
          "free_ride": "Favorable",
          "high_alert": "Vigilance rouge",
          "spring_diurnal": "Situation de printemps"
        }
      },
      "decisionTree": {
        "filepath": "./assets/img/decision_tree-en.svg",
        "feedbackURL": "https://admin.avalanche.report/umfrage-entscheidungsbaum/en/",
        "decisionTree": "Arbre de décision",
        "feedback": "Retour d'informations",
        "save": "Sauvegarder",
        "discard": "Abandonner"
      }
    },
    "table": {
      "title": {
        "date": "Date",
        "status": {
          "AT-02": "Carinthie",
          "AT-03": "Basse-Autriche",
          "AT-04": "Haute-Autriche",
          "AT-05": "Salzbourg",
          "AT-06": "Styrie",
          "AT-07": "Tyrol",
          "AT-08": "Vorarlberg",
          "CH": "Suisse",
          "DE-BY": "Bavière",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentin",
          "IT-32-BZ": "Tyrol du Sud",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "manquant",
        "draft": "ébauche",
        "submitted": "envoyé",
        "published": "publié",
        "updated": "mis à jour",
        "resubmitted": "envoyé à nouveau",
        "republished": "publié à nouveau"
      },
      "publishBulletinsDialog": {
        "message": "Publier le bulletin ?",
        "missingDangerRating": "Indice de risque non défini",
        "missingRegion": "Régions non évaluée",
        "duplicateRegion": "Régions notées plusieurs fois",
        "missingAvActivityHighlights": "Titre situation avalancheuse manque",
        "missingAvActivityComment": "Description du danger d'avalanche manque",
        "missingSnowpackStructureHighlights": "Titre structure du manteau neigeux manque",
        "missingSnowpackStructureComment": "Description structure du manteau neigeux manque",
        "pendingSuggestions": "Propositions en attente",
        "incompleteTranslation": "Traduction incomplète",
        "accept": "Oui",
        "reject": "Non"
      },
      "publicationStatusDialog": {
        "headline": "Statut de publication",
        "publishedBy": "Publié par :",
        "publishedAt": "Publié à :",
        "validFor": "Valide pour:",
        "title": {
          "prod": "Système productif",
          "test": "Test système",
          "task": "Tâche",
          "language": {
            "all": "TOUT",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "email": {
          "label": "E-mails",
          "tooltip": "Envoyer e-mails",
          "success": "{{prefix}}mails envoyés",
          "error": "Échec envoi {{prefix}}mails !"
        },
        "telegram": {
          "label": "Messages Telegram",
          "tooltip": "Envoyer messages Telegram",
          "success": "{{prefix}}messages Telegram envoyés",
          "error": "Échec envoi {{prefix}}messages Telegram !"
        },
        "push": {
          "label": "Notifications push",
          "tooltip": "Envoyer notifications push",
          "success": "{{prefix}}notifications push envoyés",
          "error": "Échec envoi {{prefix}}notifications push !"
        },
        "accept": "Ok"
      },
      "mediaFileDialog": {
        "headline": "Fichier média",
        "important": "IMPORTANT",
        "missingFile": "Aucun fichier sélectionné !",
        "uploadError": "Échec du téléchargement !",
        "accept": "Envoyer",
        "reject": "Annuler"
      },
      "publishBulletinsErrorDialog": {
        "message": "Erreur lors de la publication des bulletins !",
        "accept": "Ok"
      },
      "submitBulletinsDialog": {
        "message": "Envoyer le bulletin ?",
        "missingDangerRating": "Indice de risque non défini",
        "missingRegion": "Régions non évaluée",
        "missingAvActivityHighlights": "Titre situation avalancheuse manque",
        "missingAvActivityComment": "Description du danger d'avalanche manque",
        "missingSnowpackStructureHighlights": "Titre structure du manteau neigeux manque",
        "missingSnowpackStructureComment": "Description structure du manteau neigeux manque",
        "pendingSuggestions": "Propositions en attente",
        "accept": "Oui",
        "reject": "Non"
      },
      "submitBulletinsErrorDialog": {
        "message": "Erreur lors de l'envoi des bulletins !",
        "accept": "Ok"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "Régions de prévision évaluées plusieurs fois ! Impossible d'envoyer le bulletin.",
        "accept": "Ok"
      },
      "checkBulletinsErrorDialog": {
        "message": "Erreur lors du contrôle des bulletins !",
        "accept": "Ok"
      },
      "previewErrorDialog": {
        "message": "Impossible de charger l'aperçu",
        "accept": "Ok"
      },
      "checkBulletinsDialog": {
        "message": "Contrôler le bulletin",
        "missingDangerRating": "Indice de risque non défini",
        "missingRegion": "Régions non évaluée",
        "missingAvActivityHighlights": "Titre situation avalancheuse manque",
        "missingAvActivityComment": "Description du danger d'avalanche manque",
        "missingSnowpackStructureHighlights": "Titre structure du manteau neigeux manque",
        "missingSnowpackStructureComment": "Description structure du manteau neigeux manque",
        "pendingSuggestions": "Propositions en attente",
        "incompleteTranslation": "Traduction incomplète",
        "ok": "(tout bon)",
        "accept": "Ok"
      },
      "publishAllDialog": {
        "message": "Publier toutes les régions ?",
        "accept": "Oui",
        "reject": "Non"
      }
    }
  },
  "training": {
    "training": "Exercice",
    "timestamp": "Moment de l'exercice"
  },
  "admin": {
    "loadingStatistics": "CSV en cours de création...",
    "server-configuration": {
      "title": "Serveur",
      "button": {
        "create": "Ajouter serveur",
        "save": "Sauvegarder"
      },
      "table": {
        "name": "Nom",
        "username": "Nom d'utilisateur",
        "password": "Mot de passe",
        "publishAt5PM": "Publier à 17h00",
        "publishAt8AM": "Publier à 08h00",
        "pdfDirectory": "Répertoire pour PDF",
        "htmlDirectory": "Répertoire pour HTML",
        "serverImagesUrl": "Chemin vers les images du serveur",
        "mapsPath": "Chemin vers les cartes",
        "mediaPath": "Chemin vers les fichiers média",
        "mapProductionUrl": "URL pour la production des cartes",
        "apiUrl": "API URL"
      },
      "success": "Configuration sauvegardée avec succès",
      "error": "Impossible de sauvegarder la configuration"
    },
    "region-configuration": {
      "title": "Région de prévision",
      "button": {
        "create": "Créer région de prévision",
        "save": "Sauvegarder"
      },
      "table": {
        "general": {
          "title": "Général",
          "id": "ID",
          "microRegions": "Nombre de microrégions",
          "subRegions": "Sous-régions",
          "superRegions": "Super-régions",
          "neighborRegions": "Régions voisines"
        },
        "publication": {
          "title": "Publication",
          "publishBulletins": "Publier prévision d'avalanches",
          "publishBlogs": "Publier articles de blog",
          "createCaamlV5": "Créer CAAML v5",
          "createCaamlV6": "Créer CAAML v6",
          "createJson": "Créer JSON",
          "createMaps": "Créer cartes",
          "createPdf": "Créer PDF",
          "sendEmails": "Envoyer mails",
          "createSimpleHtml": "Créer HTML simple",
          "sendTelegramMessages": "Envoyer messages Telegram",
          "sendPushNotifications": "Envoyer notifications push",
          "pdfColor": "PDF couleur",
          "emailColor": "Mail couleur",
          "pdfMapYAmPm": "Y pour carte PDF (am/pm)",
          "pdfMapYFd": "Y pour carte PDF (fd)",
          "pdfMapWidthAmPm": "Largeur de carte pour PDF (am/pm)",
          "pdfMapWidthFd": "Largeur de carte pour PDF (fd)",
          "pdfMapHeight": "Hauteur de carte pour PDF",
          "pdfFooterLogo": "Logo pour pied de page PDF",
          "pdfFooterLogoColorPath": "Logo pour pied de page PDF (couleur)",
          "pdfFooterLogoBwPath": "Logo pour pied de page PDF (noir et blanc)",
          "mapXmax": "Carte X max",
          "mapXmin": "Carte X min",
          "mapYmax": "Carte Y max",
          "mapYmin": "Carte Y min",
          "simpleHtmlTemplateName": "Modèle HTML simple",
          "geoDataDirectory": "Répertoire géodonnées",
          "mapLogoColorPath": "Logo pour carte (couleur)",
          "mapLogoBwPath": "Logo pour carte (noir et blanc)",
          "mapLogoPosition": "Position du logo pour carte",
          "mapCenterLat": "Centre de la carte (lat)",
          "mapCenterLng": "Centre de la carte (long)",
          "imageColorbarColorPath": "Barre de couleurs (couleur)",
          "imageColorbarBwPath": "Barre de couleurs (noir et blanc)"
        },
        "components": {
          "title": "Composantes",
          "enableDangerSources": "Activer sources de dangers",
          "enableObservations": "Activer observations",
          "enableModelling": "Activer modélisation",
          "enableWeatherbox": "Activer boîte météo"
        },
        "configuration": {
          "title": "Configuration",
          "showMatrix": "Afficher matrice",
          "enableMediaFile": "Activer fichier média",
          "enableStrategicMindset": "Activer état d'esprit stratégique",
          "enableStressLevel": "Activer niveau de stress",
          "enableAvalancheProblemCornices": "Activer situation avalancheuse CONGÈRES",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Activer situation avalancheuse PAS DE SITUATION PARTICULIÈRE"
        }
      },
      "success": "Configuration sauvegardée avec succès",
      "error": "Impossible de sauvegarder la configuration"
    },
    "users": {
      "title": "Utilisateurs",
      "button": {
        "create": "Créer utilisateur"
      },
      "tooltip": {
        "edit": "Éditer utilisateur",
        "changePassword": "Modifier le mot de passe",
        "delete": "Supprimer utilisateur"
      },
      "table": {
        "name": "Nom",
        "email": "Mail",
        "organization": "Organisation",
        "regions": "Régions de prévision",
        "roles": "Fonctions"
      },
      "deleteUserModalDialog": {
        "message": "Voulez-vous supprimer l'utilisateur ?",
        "accept": "Oui",
        "reject": "Non"
      },
      "deleteUser": {
        "success": "Utilisateur supprimé",
        "error": "Impossible de supprimer l'utilisateur"
      },
      "createUser": {
        "success": "Utilisateur créé",
        "error": "Impossible de créer l'utilisateur"
      },
      "updateUser": {
        "success": "Utilisateur mis à jour",
        "error": "Mise à jour utilisateur impossible"
      }
    },
    "createUser": {
      "table": {
        "image": "Image",
        "name": "Nom",
        "email": "Mail",
        "organization": "Organisation",
        "password": "Mot de passe",
        "password2": "Répéter le mot de passe",
        "roles": "Fonctions",
        "regions": "Régions de prévision"
      },
      "error": {
        "name": {
          "required": "Veuillez saisir un nom"
        },
        "email": {
          "required": "Veuillez saisir une adresse mail",
          "invalid": "Adresse mail invalide"
        },
        "password": {
          "required": "Veuillez saisir le mot de passe",
          "length": "Le mot de passe doit contenir au moins 8 caractères",
          "mismatch": "Les mots de passe ne sont pas identiques"
        }
      },
      "button": {
        "create": "Créer utilisateur",
        "update": "Mettre à jour l'utilisateur",
        "cancel": "Annuler"
      }
    },
    "observations": {
      "table": "Tableau",
      "map": "Carte",
      "gallery": "Galerie",
      "filters": "Filtres",
      "title": "Observations",
      "newObservation": "Ajouter une observation",
      "exportObservations": "Exporter observations",
      "toggleFilterBar": "Afficher/masquer barre de filtres",
      "button": {
        "submit": "Télécharger"
      },
      "label": {
        "dateRange": "Période",
        "observer": "Observateur"
      },
      "selectRegion": {
        "placeholder": "Région de prévision",
        "selectionLabel": "{0} région(s) sélectionnée(s)"
      },
      "selectSources": {
        "placeholder": "Sources",
        "selectionLabel": "{0} source(s) sélectionnée(s)"
      },
      "charts": {
        "charts": "Diagramme",
        "nan": "Pas d'informations",
        "classify": "Classifier",
        "label": "Description",
        "invert": "Inverser",
        "reset": "Réinitialiser",
        "aspect": {
          "caption": "Exposition"
        },
        "elevation": {
          "caption": "Altitude"
        },
        "stability": {
          "caption": "Stabilité"
        },
        "observationType": {
          "caption": "Type d'observation"
        },
        "importantObservation": {
          "caption": "Important"
        },
        "avalancheProblem": {
          "caption": "Situation avalancheuse typique"
        },
        "dangerPattern": {
          "caption": "Modèle de risques"
        },
        "days": {
          "caption": "Jour"
        }
      }
    },
    "blog": {
      "title": "Blog",
      "language": {
        "title": "Langue",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Envoyer mails, messages Telegram et notifications push",
        "success": "{{prefix}}mail, message Telegram et notification push envoyés",
        "error": "Échec envoi {{prefix}}mail, message Telegram et notification push !"
      },
      "email": {
        "tooltip": "Envoyer mails",
        "success": "{{prefix}}mail envoyé",
        "error": "Échec envoi {{prefix}}mail !"
      },
      "telegram": {
        "tooltip": "Envoyer via un canal Telegram",
        "success": "{{prefix}}message Telegram envoyé",
        "error": "Échec envoi {{prefix}}message Telegram !"
      },
      "push": {
        "tooltip": "Envoyer notifications push",
        "success": "{{prefix}}notification push envoyé",
        "error": "Échec envoi {{prefix}}notification push !"
      }
    }
  },
  "settings": {
    "title": "Paramètres",
    "changePassword": {
      "title": "Modifier le mot de passe",
      "oldPassword": {
        "label": "Mot de passe actuel",
        "placeholder": "Mot de passe actuel"
      },
      "newPassword1": {
        "label": "Nouveau mot de passe",
        "placeholder": "Nouveau mot de passe"
      },
      "newPassword2": {
        "label": "Nouveau mot de passe (répéter)",
        "placeholder": "Nouveau mot de passe"
      },
      "submit": "Modifier le mot de passe",
      "passwordChanged": "Mot de passe changé avec succès",
      "passwordChangeError": "Impossible de changer le mot de passe",
      "passwordIncorrect": "Mot de passe actuel incorrect",
      "error": {
        "oldPassword": {
          "required": "Saisissez votre mot de passe actuel"
        },
        "newPassword": {
          "required": "Saisissez votre nouveau mot de passe ",
          "mismatch": "Les mots de passe ne sont pas identiques"
        }
      }
    },
    "display": {
      "title": "Afficher",
      "table": {
        "showCaaml": "Afficher CAAML",
        "showJson": "Afficher JSON"
      }
    }
  },
  "statistics": {
    "title": "Statistiques",
    "button": {
      "submit": "Télécharger"
    },
    "label": {
      "dateRange": "Période",
      "extended": "Étendue",
      "duplicates": "Doublons"
    }
  },

  "dangerRating": {
    "noRating": "pas noté",
    "low": "faible",
    "moderate": "limité",
    "considerable": "marqué",
    "high": "fort",
    "veryHigh": "très fort"
  },
  "avalancheProblem": {
    "newSnow": "Neige fraîche",
    "new_snow": "Neige fraîche",
    "windSlab": "Plaque à vent",
    "wind_slab": "Plaque à vent",
    "persistentWeakLayers": "Neige ancienne (Sous-couches fragiles persistantes)",
    "persistent_weak_layers": "Neige ancienne (Sous-couches fragiles persistantes)",
    "wetSnow": "Avalanches mouillées (Neige humide)",
    "wet_snow": "Avalanches mouillées (Neige humide)",
    "glidingSnow": "Avalanches de glissement (Avalanches de fond)",
    "gliding_snow": "Avalanches de glissement (Avalanches de fond)",
    "cornices": "Congères",
    "noDistinctAvalancheProblem": "Pas de situation avalancheuse particulière",
    "no_distinct_avalanche_problem": "Pas de situation avalancheuse particulière",
    "favourableSituation": "Situation favorable",
    "favourable_situation": "Situation favorable"
  },
  "dangerPattern": {
    "dp1": "md.1: sous-couche fragile persistante",
    "dp2": "md.2: neige glissante",
    "dp3": "md.3: pluie",
    "dp4": "md.4: froid suivant chaud / chaud suivant froid",
    "dp5": "md.5: chute de neige après une longue période de froid",
    "dp6": "md.6: froid, neige légère et vent",
    "dp7": "dp.7: shallow snow next to deep snow",
    "dp8": "md.8: givre de surface enneigé",
    "dp9": "md.9: neige roulée enneigé",
    "dp10": "md.10: situation de printemps"
  },
  "strategicMindset": {
    "title": "État d'esprit stratégique",
    "assessment": "Grande incertitude",
    "stepping_out": "En cours d'amélioration",
    "status_quo": "Statu quo",
    "stepping_back": "En cours de détérioration",
    "entrenchment": "Persistante instable",
    "free_ride": "Favorable",
    "high_alert": "Vigilance rouge",
    "spring_diurnal": "Situation de printemps"
  },
  "observationType": {
    "TimeSeries": "Série chronologique",
    "Incident": "Incident",
    "Profile": "Profil de neige",
    "Closure": "Fermeture",
    "Blasting": "Minage",
    "Avalanche": "Avalanche",
    "Evaluation": "Évaluation",
    "SimpleObservation": "Observation simple",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "ForBlog": "Pour le blog",
    "SnowLine": "Limite de l'enneigement",
    "SurfaceHoar": "Givre de surface",
    "Graupel": "Neige roulée",
    "StabilityTest": "Test de stabilité",
    "IceFormation": "Formation de glace",
    "VeryLightNewSnow": "Neige fraîche très légère"
  },
  "aspect": {
    "N": "N",
    "NE": "NE",
    "E": "E",
    "SE": "SE",
    "S": "S",
    "SW": "SO",
    "W": "O",
    "NW": "NO"
  },
  "role": {
    "SUPERADMIN": "Superadministrateur",
    "ADMIN": "Administrateur",
    "FORECASTER": "Prévisionniste",
    "FOREMAN": "Contremaître",
    "OBSERVER": "Observateur"
  },
  "tendency": {
    "decreasing": "Le danger d'avalanche diminue",
    "steady": "Le danger d'avalanche reste inchangé",
    "increasing": "Le danger d'avalanche augmente"
  },
  "snowpackStability": {
    "unknown": "inconnu",
    "good": "bon",
    "fair": "moyen",
    "poor": "mauvais",
    "very_poor": "très mauvais"
  },
  "frequency": {
    "none": "(pratiquement) pas",
    "few": "quelques-uns",
    "some": "plusieurs",
    "many": "beaucoup"
  },
  "avalancheSize": {
    "small": "petit",
    "medium": "moyen",
    "large": "large",
    "very_large": "très large",
    "extreme": "extrême"
  },
  "complexity": {
    "easy": "Facile",
    "challenging": "Exigeant",
    "complex": "Complexe"
  },
  "glidingSnowActivity": {
    "low": "faible",
    "medium": "moyen",
    "high": "fort"
  },
  "wetness": {
    "dry": "sec",
    "moist": "mouillé",
    "wet": "humide"
  },
  "characteristic": {
    "low": "faible",
    "medium": "moyen",
    "high": "fort",
    "very_high": "très fort"
  },
  "thickness": {
    "thin": "mince",
    "thick": "épais"
  },
  "snowpackPosition": {
    "upper": "au-dessus",
    "middle": "au milieu",
    "lower": "en-dessous",
    "ground": "sol"
  },
  "creationProcess": {
    "radiation_recrystallization": "rayonnement",
    "diurnal_recrystallization": "Croûte de regel",
    "melt_layer_recrystallization": "couche de fonte"
  },
  "recognizability": {
    "very_easy": "très facile",
    "easy": "facile",
    "hard": "difficile",
    "very_hard": "très difficile"
  },
  "distribution": {
    "isolated": "isolé",
    "specific": "définissable",
    "widespread": "étendu"
  },
  "probability": {
    "likely": "probable",
    "possible": "possible",
    "unlikely": "improbable"
  },
  "detailedDaytime": {
    "evening": "soir",
    "first_night_half": "première partie de nuit",
    "second_night_half": "seconde partie de nuit",
    "morning": "tôt le matin",
    "forenoon": "matin",
    "afternoon": "après-midi"
  },
  "slopeGradient": {
    "moderately_steep": "modérément raide",
    "steep": "raide",
    "very_steep": "très raide",
    "extremely_steep": "extrêmement raide"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "plus meuble au-dessous",
    "steady": "régulier",
    "increasing": "plus meuble au-dessus"
  },
  "terrainType": {
    "gullies": "couloirs",
    "bowls": "creux",
    "pass_areas": "zones de col",
    "ridge_lines": "lignes de crête"
  },
  "dangerSign": {
    "shooting_cracks": "fissures",
    "whumpfing": "bruit d'effondrement"
  },
  "changeRegionDialog": {
    "message": "Voulez-vous changer de région de prévision ?",
    "accept": "Oui",
    "reject": "Non"
  },
  "zamg": {
    "table": "Tableau",
    "map": "Carte",
    "region": "Région de prévision",
    "newSnow": "Neige fraîche"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "neige humide: teneur en eau liquide",
      "wet_snow_instability": "neige humide: instabilité de la neige mouillée",
      "Sk38_stratigraphy": "couches fragiles persistantes: SK38 index de stabilité",
      "stratigraphy": "couches fragiles persistantes : stratigraphie de la neige"
    },
    "aspect": {
      "flat": "terrain plat",
      "north": "versant nord",
      "south": "versant sud"
    },
    "meteo": {
      "new_snow_plot_3day": "neige fraîche (3j)",
      "new_snow_plot_7day": "neige fraîche (7j)",
      "new_snow_plot_1month": "neige fraîche (31j)",
      "new_snow_plot_season": "neige fraîche (toute la saison)",
      "new_snow_plot_forecast": "neige fraîche (prévisons)",
      "wet_snow_plot_3day": "neige humide (3j)",
      "wet_snow_plot_7day": "neige humide (7j)",
      "wet_snow_plot_1month": "neige humide (31j)",
      "wet_snow_plot_season": "neige humide (toute la saison)",
      "wet_snow_plot_forecast": "neige humide (prévisions)",
      "HS_table_24h": "tableau hauteur de neige (1j)",
      "HS_table_72h": "tableau hauteur de neige (3j)",
      "HS_table_season": "tableau hauteur de neige (toute la saison)",
      "HS_table_forecast": "tableau hauteur de neige (prévisions)",
      "TA_table_24h": "tableau température (1j)",
      "TA_table_72h": "tableau température (3j)",
      "TA_table_season": "tableau température (toute la saison)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Neige fraîche"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Neige roulée"
    },
    "DF": {
      "code": "DF",
      "class": "Particules reconnaissables"
    },
    "RG": {
      "code": "RG",
      "class": "Grains arrondis"
    },
    "FC": {
      "code": "FC",
      "class": "Grains anguleux"
    },
    "DH": {
      "code": "DH",
      "class": "Givre de profondeur"
    },
    "SH": {
      "code": "SH",
      "class": "Givre de surface"
    },
    "MF": {
      "code": "MF",
      "class": "Formes de fonte"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Croûte de regel"
    },
    "IF": {
      "code": "IF",
      "class": "Formations de glace"
    }
  },
  "button": {
    "yes": "oui",
    "no": "non"
  },
  "observations": {
    "allowEdit": "Éditer observations externes",
    "allowEditTooltip": "Cela évitera l'écrasement par {{ value }}",
    "editIconTooltip": "Observation éditée - sans mise à jour par {{ value }}",
    "aspect": "Exposition",
    "authorName": "Nom de l'auteur",
    "avalancheLength": "Longueur d'avalanche",
    "avalancheProblem": "Situation avalancheuse typique",
    "avalancheSize": "Taille d'avalanche",
    "avalancheType": "Type d'avalanche",
    "avalancheWidth": "Largeur d'avalanche",
    "search": "Rechercher",
    "count": "# observations :",
    "layers": {
      "observers": "Observateurs",
      "weatherStations": "Stations météo ",
      "webcams": "Webcams",
      "observations": "Observations"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Rayonnement global",
        "newSnow": "Neige fraîche",
        "snowLine": "Limite de l'enneigement",
        "snowHeight": "Hauteur de neige",
        "snowDifference24h": "Différence de hauteur de neige 24h",
        "snowDifference48h": "Différence de hauteur de neige 48h",
        "snowDifference72h": "Différence de hauteur de neige 72h",
        "airTemperature": "Température de l'air",
        "airTemperatureMax": "Température maximale de l'air",
        "airTemperatureMin": "Température minimale de l'air",
        "surfaceTemperature": "Température à la surface",
        "surfaceHoar": "Formation potentielle de givre de surface",
        "surfaceHoarCalc": "Potential surface hoar formation (Lehning et. al.)",
        "dewPoint": "Température de rosée",
        "relativeHumidity": "Humidité relative",
        "windDirection": "Direction du vent",
        "windSpeed": "Vitesse du vent",
        "windGust": "Rafale de vent"
      }
    },
    "button": {
      "add": "Ajouter une observation",
      "save": "Enregistrer l'observation",
      "delete": "Supprimer l'observation",
      "deleteConfirm": "Supprimer l'observation ?",
      "discard": "Abandonner",
      "copyDate": "Copier date de l'événement"
    },
    "comment": "Commentaire",
    "content": "Contenu",
    "dangerRating": "Indice de risques",
    "elevation": "Altitude",
    "elevationRange": "Plage d'altitude",
    "elevationLowerBound": "Limite inférieure",
    "elevationUpperBound": "Limite supérieure",
    "eventDate": "Date de l'événement",
    "eventType": "Type d'événement",
    "eventTypes": {
      "IMPORTANT": "Événement important",
      "NEIGHBOR_REGION": "Région voisine",
      "NORMAL": "Événement normal",
      "PERSON_DEAD": "Personnes emportées (décès)",
      "PERSON_INJURED": "Personnes emportées (blessures)",
      "PERSON_NO": "Pas de personnes emportées",
      "PERSON_UNINJURED": "Personnes emportées (aucun blessé)",
      "PERSON_UNKNOWN": "Événement (inconnu)",
      "TRAFFIC": "Trafic"
    },
    "externalURL": "URL externe (p. ex. banque d'images)",
    "fractureDepth": "Épaisseur de la cassure",
    "hasCoordinates": "Coordonnées disponibles",
    "incline": "Inclinaison de pente",
    "latitude": "Latitude",
    "locationName": "Localisation",
    "longitude": "Longitude",
    "observationType": "Type d'observation",
    "personInvolvement": "Implication de personnes",
    "reportDate": "Date du rapport",
    "showTable": "Tableau",
    "withoutCoordinates": "Observations sans coordonnées :",
    "sidebar": {
      "filter": {
        "title": "Filtre"
      },
      "legend": {
        "title": "Légende"
      },
      "sources": {
        "title": "Sources des données"
      },
      "views": {
        "title": "Vues"
      }
    }
  },
  "personInvolvement": {
    "Dead": "Personnes emportées (décès)",
    "Injured": "Personnes emportées (blessures)",
    "No": "Pas de personnes emportées",
    "Uninjured": "Personnes emportées (aucun blessé)",
    "Unknown": "Événement (inconnu)"
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Jour",
    "time": "Heure",
    "back": "Retour",
    "parameters": {
      "DUST": "poussière du Sahara",
      "N": "couverture nuageuse en /8",
      "Nh": "couverture nuageuse élevée en /8",
      "Nm": "couverture nuageuse moyenne en /8",
      "Nl": "faible couverture nuageuse en /8",
      "N-CU": "couverture de cumulus en /8",
      "AUSL.T": "Température de déclenchement",
      "SHW-IX": "Afficher alter-Index",
      "WX -CUF": "temps significatif : NIL (rien), RASH (pluie), TS (orage)",
      "RR": "pluviométrie totale [mm]",
      "RR-str": "précipitations stratiformes",
      "CONV": "précipitations convectives [mm]",
      "SN": "précipitations sous forme de neige [mm]",
      "SN--RA": "limite de l'enneigement [m]",
      "QAO ": "{{value}} hPa eq. vent moyen, direction et force, [°] et [m/s]",
      "QAN": "vent moyen au sol, direction et force, [°] et [m/s]",
      "QANmax": "vent maximal (rafales) au sol, [m/s]",
      "T ": "{{value}} hPa eq. température [°C]",
      "T m": "température à {{value}}m [°C]",
      "T 2m": "température à 2m [°C]",
      "Tk 2m": "température à 2m [°K]",
      "T GND": "température au sol",
      "Max-ADI": "température maximale de mélange [°C]",
      "Min Max": "température min./max. ces dernières 24h",
      "FROST": "ligne de zéro degré [m]",
      "W cm": "500 hPa eq. vent vertical [cm/s]",
      "W --": "700 hPa eq. vent vertical [cm/s]",
      "W s": "{{value}} hPa eq. vent vertical [cm/s]",
      "H ": "{{value}} hPa hauteur géopotentielle [m]",
      "RF ": "{{value}} hPa eq. humidité relative [%]"
    }
  }
}
