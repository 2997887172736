var pt = Object.defineProperty;
var C = Math.pow;
var c = (a, e) => pt(a, "name", {
  value: e,
  configurable: !0
});
var D = (a, e, t) => new Promise((r, n) => {
  var i = o => {
      try {
        l(t.next(o));
      } catch (f) {
        n(f);
      }
    },
    s = o => {
      try {
        l(t.throw(o));
      } catch (f) {
        n(f);
      }
    },
    l = o => o.done ? r(o.value) : Promise.resolve(o.value).then(i, s);
  l((t = t.apply(a, e)).next());
});
import B from "@mapbox/point-geometry";
import { mix as Ee } from "color2k";
import X from "@mapbox/point-geometry";
var he = class he {
  constructor(e, t) {
    this.str = e != null ? e : t, this.perFeature = typeof this.str == "function" && this.str.length === 2;
  }
  get(e, t) {
    return typeof this.str == "function" ? this.str(e, t) : this.str;
  }
};
c(he, "StringAttr");
var M = he,
  be = class be {
    constructor(e, t = 1) {
      this.value = e != null ? e : t, this.perFeature = typeof this.value == "function" && this.value.length === 2;
    }
    get(e, t) {
      return typeof this.value == "function" ? this.value(e, t) : this.value;
    }
  };
c(be, "NumberAttr");
var T = be,
  pe = class pe {
    constructor(e) {
      var t;
      this.labelProps = (t = e == null ? void 0 : e.labelProps) != null ? t : ["name"], this.textTransform = e == null ? void 0 : e.textTransform;
    }
    get(e, t) {
      let r, n;
      typeof this.labelProps == "function" ? n = this.labelProps(e, t) : n = this.labelProps;
      for (let s of n) if (Object.prototype.hasOwnProperty.call(t.props, s) && typeof t.props[s] == "string") {
        r = t.props[s];
        break;
      }
      let i;
      return typeof this.textTransform == "function" ? i = this.textTransform(e, t) : i = this.textTransform, r && i === "uppercase" ? r = r.toUpperCase() : r && i === "lowercase" ? r = r.toLowerCase() : r && i === "capitalize" && (r = r.toLowerCase().split(" ").map(o => o[0].toUpperCase() + o.slice(1)).join(" ")), r;
    }
  };
c(pe, "TextAttr");
var E = pe,
  ge = class ge {
    constructor(e) {
      var t, r;
      e != null && e.font ? this.font = e.font : (this.family = (t = e == null ? void 0 : e.fontFamily) != null ? t : "sans-serif", this.size = (r = e == null ? void 0 : e.fontSize) != null ? r : 12, this.weight = e == null ? void 0 : e.fontWeight, this.style = e == null ? void 0 : e.fontStyle);
    }
    get(e, t) {
      if (this.font) return typeof this.font == "function" ? this.font(e, t) : this.font;
      let r = "";
      this.style && (typeof this.style == "function" ? r = `${this.style(e, t)} ` : r = `${this.style} `);
      let n = "";
      this.weight && (typeof this.weight == "function" ? n = `${this.weight(e, t)} ` : n = `${this.weight} `);
      let i;
      typeof this.size == "function" ? i = this.size(e, t) : i = this.size;
      let s;
      return typeof this.family == "function" ? s = this.family(e, t) : s = this.family, `${r}${n}${i}px ${s}`;
    }
  };
c(ge, "FontAttr");
var N = ge,
  ye = class ye {
    constructor(e, t = []) {
      this.value = e != null ? e : t, this.perFeature = typeof this.value == "function" && this.value.length === 2;
    }
    get(e, t) {
      return typeof this.value == "function" ? this.value(e, t) : this.value;
    }
  };
c(ye, "ArrayAttr");
var q = ye;
import qe from "@mapbox/point-geometry";
var gt = c((a, e, t) => {
  let r = [],
    n,
    i,
    s,
    l = 0,
    o = 0,
    f = 0,
    u = 0,
    m = 0,
    d = 0,
    b = 0,
    g = 0,
    y = 0,
    _ = 0,
    k = 0,
    p = 0;
  if (a.length < 2) return [];
  if (a.length === 2) return f = Math.sqrt(C(a[1].x - a[0].x, 2) + C(a[1].y - a[0].y, 2)), [{
    length: f,
    beginIndex: 0,
    beginDistance: 0,
    endIndex: 2,
    endDistance: f
  }];
  for (u = Math.sqrt(C(a[1].x - a[0].x, 2) + C(a[1].y - a[0].y, 2)), l = 1, o = a.length - 1; l < o; l++) n = a[l - 1], i = a[l], s = a[l + 1], d = i.x - n.x, b = i.y - n.y, g = s.x - i.x, y = s.y - i.y, m = Math.sqrt(g * g + y * y), f += u, _ = Math.acos((d * g + b * y) / (u * m)), (_ > e || f - p > t) && (r.push({
    length: f - p,
    beginDistance: p,
    beginIndex: k,
    endIndex: l + 1,
    endDistance: f
  }), k = l, p = f), u = m;
  return l - k > 0 && r.push({
    length: f - p + m,
    beginIndex: k,
    beginDistance: p,
    endIndex: l + 1,
    endDistance: f + m
  }), r;
}, "linelabel");
function Ue(a, e, t, r) {
  let n = [];
  for (let i of a) {
    let s = gt(i, Math.PI / 45, e);
    for (let l of s) if (l.length >= e + r) {
      let o = new qe(i[l.beginIndex].x, i[l.beginIndex].y),
        f = i[l.endIndex - 1],
        u = new qe((f.x - o.x) / l.length, (f.y - o.y) / l.length);
      for (let m = r; m < l.length - e; m += t) n.push({
        start: o.add(u.mult(m)),
        end: o.add(u.mult(m + e))
      });
    }
  }
  return n;
}
c(Ue, "simpleLabel");
function Ge(a, e, t, r) {
  let n = e.x - a.x,
    i = e.y - a.y,
    s = Math.sqrt(C(e.x - a.x, 2) + C(e.y - a.y, 2)),
    l = [];
  for (let o = 0; o < t + r; o += 2 * r) {
    let f = o * 1 / s;
    l.push({
      x: a.x + f * n,
      y: a.y + f * i
    });
  }
  return l;
}
c(Ge, "lineCells");
function xe(a, e) {
  if (a.length <= e) return [a];
  let t = e - 1,
    r = a.lastIndexOf(" ", t),
    n = a.indexOf(" ", t);
  if (r === -1 && n === -1) return [a];
  let i, s;
  return n === -1 || r >= 0 && t - r < n - t ? (i = a.substring(0, r), s = a.substring(r + 1, a.length)) : (i = a.substring(0, n), s = a.substring(n + 1, a.length)), [i, ...xe(s, e)];
}
c(xe, "linebreak");
var yt = "\u3005-\u3007\u3021-\u3029\u3031-\u3035\u3038-\u303C\u3220-\u3229\u3248-\u324F\u3251-\u325F\u3280-\u3289\u32B1-\u32BF\u3400-\u4DB5\u4E00-\u9FEA\uF900-\uFA6D\uFA70-\uFAD9\u2000",
  ir = new RegExp(`^[${yt}]+$`);
import W from "@mapbox/point-geometry";
import { VectorTile as xt } from "@mapbox/vector-tile";
import _t from "pbf";
import { PMTiles as wt } from "pmtiles";
var rt = (r => (r[r.Point = 1] = "Point", r[r.Line = 2] = "Line", r[r.Polygon = 3] = "Polygon", r))(rt || {});
function Y(a) {
  return `${a.x}:${a.y}:${a.z}`;
}
c(Y, "toIndex");
var kt = c((a, e, t) => {
  a.pos = e;
  let r = a.readVarint() + a.pos,
    n = 1,
    i = 0,
    s = 0,
    l = 0,
    o = 1 / 0,
    f = -1 / 0,
    u = 1 / 0,
    m = -1 / 0,
    d = [],
    b = [];
  for (; a.pos < r;) {
    if (i <= 0) {
      let g = a.readVarint();
      n = g & 7, i = g >> 3;
    }
    if (i--, n === 1 || n === 2) s += a.readSVarint() * t, l += a.readSVarint() * t, s < o && (o = s), s > f && (f = s), l < u && (u = l), l > m && (m = l), n === 1 && (b.length > 0 && d.push(b), b = []), b.push(new W(s, l));else if (n === 7) b && b.push(b[0].clone());else throw new Error(`unknown command ${n}`);
  }
  return b && d.push(b), {
    geom: d,
    bbox: {
      minX: o,
      minY: u,
      maxX: f,
      maxY: m
    }
  };
}, "loadGeomAndBbox");
function nt(a, e) {
  let t = new xt(new _t(a)),
    r = new Map();
  for (let [n, i] of Object.entries(t.layers)) {
    let s = [],
      l = i;
    for (let o = 0; o < l.length; o++) {
      let f = kt(l.feature(o)._pbf, l.feature(o)._geometry, e / l.extent),
        u = 0;
      for (let m of f.geom) u += m.length;
      s.push({
        id: l.feature(o).id,
        geomType: l.feature(o).type,
        geom: f.geom,
        numVertices: u,
        bbox: f.bbox,
        props: l.feature(o).properties
      });
    }
    r.set(n, s);
  }
  return r;
}
c(nt, "parseTile");
var we = class we {
  constructor(e, t) {
    typeof e == "string" ? this.p = new wt(e) : this.p = e, this.zoomaborts = [], this.shouldCancelZooms = t;
  }
  get(e, t) {
    return D(this, null, function* () {
      this.shouldCancelZooms && (this.zoomaborts = this.zoomaborts.filter(s => s.z !== e.z ? (s.controller.abort(), !1) : !0));
      let r = new AbortController();
      this.zoomaborts.push({
        z: e.z,
        controller: r
      });
      let n = r.signal,
        i = yield this.p.getZxy(e.z, e.x, e.y, n);
      return i ? nt(i.data, t) : new Map();
    });
  }
};
c(we, "PmtilesSource");
var H = we,
  ke = class ke {
    constructor(e, t) {
      this.url = e, this.zoomaborts = [], this.shouldCancelZooms = t;
    }
    get(e, t) {
      return D(this, null, function* () {
        this.shouldCancelZooms && (this.zoomaborts = this.zoomaborts.filter(s => s.z !== e.z ? (s.controller.abort(), !1) : !0));
        let r = this.url.replace("{z}", e.z.toString()).replace("{x}", e.x.toString()).replace("{y}", e.y.toString()),
          n = new AbortController();
        this.zoomaborts.push({
          z: e.z,
          controller: n
        });
        let i = n.signal;
        return new Promise((s, l) => {
          fetch(r, {
            signal: i
          }).then(o => o.arrayBuffer()).then(o => {
            let f = nt(o, t);
            s(f);
          }).catch(o => {
            l(o);
          });
        });
      });
    }
  };
c(ke, "ZxySource");
var Q = ke,
  _e = 6378137,
  Qe = 85.0511287798,
  U = _e * Math.PI,
  zt = c(a => {
    let e = Math.PI / 180,
      t = Math.max(Math.min(Qe, a[0]), -Qe),
      r = Math.sin(t * e);
    return new W(_e * a[1] * e, _e * Math.log((1 + r) / (1 - r)) / 2);
  }, "project");
function et(a) {
  return a * a;
}
c(et, "sqr");
function G(a, e) {
  return et(a.x - e.x) + et(a.y - e.y);
}
c(G, "dist2");
function Tt(a, e, t) {
  let r = G(e, t);
  if (r === 0) return G(a, e);
  let n = ((a.x - e.x) * (t.x - e.x) + (a.y - e.y) * (t.y - e.y)) / r;
  return n = Math.max(0, Math.min(1, n)), G(a, new W(e.x + n * (t.x - e.x), e.y + n * (t.y - e.y)));
}
c(Tt, "distToSegmentSquared");
function tt(a, e) {
  let t = !1;
  for (let r = 0, n = e.length - 1; r < e.length; n = r++) {
    let i = e[r].x,
      s = e[r].y,
      l = e[n].x,
      o = e[n].y;
    s > a.y != o > a.y && a.x < (l - i) * (a.y - s) / (o - s) + i && (t = !t);
  }
  return t;
}
c(tt, "isInRing");
function Pt(a) {
  let e = 0;
  for (let t = 0; t < a.length; t++) {
    let r = (t + 1) % a.length;
    e += a[t].x * a[r].y, e -= a[r].x * a[t].y;
  }
  return e < 0;
}
c(Pt, "isCcw");
function Lt(a, e) {
  let t = !1;
  for (let r of e) if (Pt(r)) tt(a, r) && (t = !1);else {
    if (t) return !0;
    tt(a, r) && (t = !0);
  }
  return t;
}
c(Lt, "pointInPolygon");
function vt(a, e) {
  let t = 1 / 0;
  for (let r of e) {
    let n = Math.sqrt(G(a, r[0]));
    n < t && (t = n);
  }
  return t;
}
c(vt, "pointMinDistToPoints");
function Ct(a, e) {
  let t = 1 / 0;
  for (let r of e) for (let n = 0; n < r.length - 1; n++) {
    let i = Math.sqrt(Tt(a, r[n], r[n + 1]));
    i < t && (t = i);
  }
  return t;
}
c(Ct, "pointMinDistToLines");
var ze = class ze {
  constructor(e, t) {
    this.source = e, this.cache = new Map(), this.inflight = new Map(), this.tileSize = t;
  }
  get(e) {
    return D(this, null, function* () {
      let t = Y(e);
      return new Promise((r, n) => {
        let i = this.cache.get(t);
        if (i) i.used = performance.now(), r(i.data);else {
          let s = this.inflight.get(t);
          s ? s.push({
            resolve: r,
            reject: n
          }) : (this.inflight.set(t, []), this.source.get(e, this.tileSize).then(l => {
            this.cache.set(t, {
              used: performance.now(),
              data: l
            });
            let o = this.inflight.get(t);
            if (o) for (let f of o) f.resolve(l);
            if (this.inflight.delete(t), r(l), this.cache.size >= 64) {
              let f = 1 / 0,
                u;
              this.cache.forEach((m, d) => {
                m.used < f && (f = m.used, u = d);
              }), u && this.cache.delete(u);
            }
          }).catch(l => {
            let o = this.inflight.get(t);
            if (o) for (let f of o) f.reject(l);
            this.inflight.delete(t), n(l);
          }));
        }
      });
    });
  }
  queryFeatures(e, t, r, n) {
    let i = zt([t, e]),
      s = new W((i.x + U) / (U * 2), 1 - (i.y + U) / (U * 2));
    s.x > 1 && (s.x = s.x - Math.floor(s.x));
    let l = s.mult(1 << r),
      o = Math.floor(l.x),
      f = Math.floor(l.y),
      u = Y({
        z: r,
        x: o,
        y: f
      }),
      m = [],
      d = this.cache.get(u);
    if (d) {
      let b = new W((l.x - o) * this.tileSize, (l.y - f) * this.tileSize);
      for (let [g, y] of d.data.entries()) for (let _ of y) _.geomType === 1 ? vt(b, _.geom) < n && m.push({
        feature: _,
        layerName: g
      }) : _.geomType === 2 ? Ct(b, _.geom) < n && m.push({
        feature: _,
        layerName: g
      }) : Lt(b, _.geom) && m.push({
        feature: _,
        layerName: g
      });
    }
    return m;
  }
};
c(ze, "TileCache");
var ee = ze;
var St = (r => (r[r.Left = 1] = "Left", r[r.Center = 2] = "Center", r[r.Right = 3] = "Right", r))(St || {}),
  At = (o => (o[o.N = 1] = "N", o[o.Ne = 2] = "Ne", o[o.E = 3] = "E", o[o.Se = 4] = "Se", o[o.S = 5] = "S", o[o.Sw = 6] = "Sw", o[o.W = 7] = "W", o[o.Nw = 8] = "Nw", o))(At || {}),
  zr = c((a, e, t) => {
    let r = document.createElement("canvas"),
      n = r.getContext("2d");
    return r.width = a, r.height = e, n !== null && t(r, n), r;
  }, "createPattern"),
  ve = class ve {
    constructor(e) {
      var t;
      this.pattern = e.pattern, this.fill = new M(e.fill, "black"), this.opacity = new T(e.opacity, 1), this.stroke = new M(e.stroke, "black"), this.width = new T(e.width, 0), this.perFeature = (t = this.fill.perFeature || this.opacity.perFeature || this.stroke.perFeature || this.width.perFeature || e.perFeature) != null ? t : !1, this.doStroke = !1;
    }
    before(e, t) {
      if (!this.perFeature) {
        e.globalAlpha = this.opacity.get(t), e.fillStyle = this.fill.get(t), e.strokeStyle = this.stroke.get(t);
        let r = this.width.get(t);
        r > 0 && (this.doStroke = !0), e.lineWidth = r;
      }
      if (this.pattern) {
        let r = e.createPattern(this.pattern, "repeat");
        r && (e.fillStyle = r);
      }
    }
    draw(e, t, r, n) {
      let i = !1;
      if (this.perFeature) {
        e.globalAlpha = this.opacity.get(r, n), e.fillStyle = this.fill.get(r, n);
        let l = this.width.get(r, n);
        l && (i = !0, e.strokeStyle = this.stroke.get(r, n), e.lineWidth = l);
      }
      let s = c(() => {
        e.fill(), (i || this.doStroke) && e.stroke();
      }, "drawPath");
      e.beginPath();
      for (let l of t) for (let o = 0; o < l.length; o++) {
        let f = l[o];
        o === 0 ? e.moveTo(f.x, f.y) : e.lineTo(f.x, f.y);
      }
      s();
    }
  };
c(ve, "PolygonSymbolizer");
var P = ve;
function Tr(a, e) {
  return t => {
    let r = t - a;
    return r >= 0 && r < e.length ? e[r] : 0;
  };
}
c(Tr, "arr");
function Dt(a, e) {
  let t = 0;
  for (; e[t + 1][0] < a;) t++;
  return t;
}
c(Dt, "getStopIndex");
function Mt(a, e, t) {
  return a * (t - e) + e;
}
c(Mt, "interpolate");
function Ot(a, e, t, r) {
  let n = r[e + 1][0] - r[e][0],
    i = a - r[e][0];
  return n === 0 ? 0 : t === 1 ? i / n : (C(t, i) - 1) / (C(t, n) - 1);
}
c(Ot, "computeInterpolationFactor");
function F(a, e) {
  return t => {
    if (e.length < 1) return 0;
    if (t <= e[0][0]) return e[0][1];
    if (t >= e[e.length - 1][0]) return e[e.length - 1][1];
    let r = Dt(t, e),
      n = Ot(t, r, a, e);
    return Mt(n, e[r][1], e[r + 1][1]);
  };
}
c(F, "exp");
function Pr(a, e) {
  return t => {
    if (e.length < 1) return 0;
    let r = a;
    for (let n = 0; n < e.length; n++) t >= e[n][0] && (r = e[n][1]);
    return r;
  };
}
c(Pr, "step");
function Le(a) {
  return F(1, a);
}
c(Le, "linear");
var Ce = class Ce {
  constructor(e) {
    var t;
    this.color = new M(e.color, "black"), this.width = new T(e.width), this.opacity = new T(e.opacity), this.dash = e.dash ? new q(e.dash) : null, this.dashColor = new M(e.dashColor, "black"), this.dashWidth = new T(e.dashWidth, 1), this.lineCap = new M(e.lineCap, "butt"), this.lineJoin = new M(e.lineJoin, "miter"), this.skip = !1, this.perFeature = !!((t = this.dash) != null && t.perFeature || this.color.perFeature || this.opacity.perFeature || this.width.perFeature || this.lineCap.perFeature || this.lineJoin.perFeature || e.perFeature);
  }
  before(e, t) {
    this.perFeature || (e.strokeStyle = this.color.get(t), e.lineWidth = this.width.get(t), e.globalAlpha = this.opacity.get(t), e.lineCap = this.lineCap.get(t), e.lineJoin = this.lineJoin.get(t));
  }
  draw(e, t, r, n) {
    if (this.skip) return;
    let i = c(() => {
      this.perFeature && (e.globalAlpha = this.opacity.get(r, n), e.lineCap = this.lineCap.get(r, n), e.lineJoin = this.lineJoin.get(r, n)), this.dash ? (e.save(), this.perFeature ? (e.lineWidth = this.dashWidth.get(r, n), e.strokeStyle = this.dashColor.get(r, n), e.setLineDash(this.dash.get(r, n))) : e.setLineDash(this.dash.get(r)), e.stroke(), e.restore()) : (e.save(), this.perFeature && (e.lineWidth = this.width.get(r, n), e.strokeStyle = this.color.get(r, n)), e.stroke(), e.restore());
    }, "strokePath");
    e.beginPath();
    for (let s of t) for (let l = 0; l < s.length; l++) {
      let o = s[l];
      l === 0 ? e.moveTo(o.x, o.y) : e.lineTo(o.x, o.y);
    }
    i();
  }
};
c(Ce, "LineSymbolizer");
var O = Ce,
  Se = class Se {
    constructor(e) {
      this.name = e.name, this.sheet = e.sheet, this.dpr = window.devicePixelRatio;
    }
    place(e, t, r) {
      let n = t[0],
        i = new X(t[0][0].x, t[0][0].y),
        s = this.sheet.get(this.name),
        l = s.w / this.dpr,
        o = s.h / this.dpr,
        f = {
          minX: i.x - l / 2,
          minY: i.y - o / 2,
          maxX: i.x + l / 2,
          maxY: i.y + o / 2
        };
      return [{
        anchor: i,
        bboxes: [f],
        draw: c(m => {
          m.globalAlpha = 1, m.drawImage(this.sheet.canvas, s.x, s.y, s.w, s.h, -s.w / 2 / this.dpr, -s.h / 2 / this.dpr, s.w / 2, s.h / 2);
        }, "draw")
      }];
    }
  };
c(Se, "IconSymbolizer");
var it = Se,
  Ae = class Ae {
    constructor(e) {
      this.radius = new T(e.radius, 3), this.fill = new M(e.fill, "black"), this.stroke = new M(e.stroke, "white"), this.width = new T(e.width, 0), this.opacity = new T(e.opacity);
    }
    draw(e, t, r, n) {
      e.globalAlpha = this.opacity.get(r, n);
      let i = this.radius.get(r, n),
        s = this.width.get(r, n);
      s > 0 && (e.strokeStyle = this.stroke.get(r, n), e.lineWidth = s, e.beginPath(), e.arc(t[0][0].x, t[0][0].y, i + s / 2, 0, 2 * Math.PI), e.stroke()), e.fillStyle = this.fill.get(r, n), e.beginPath(), e.arc(t[0][0].x, t[0][0].y, i, 0, 2 * Math.PI), e.fill();
    }
    place(e, t, r) {
      let n = t[0],
        i = new X(t[0][0].x, t[0][0].y),
        s = this.radius.get(e.zoom, r),
        l = {
          minX: i.x - s,
          minY: i.y - s,
          maxX: i.x + s,
          maxY: i.y + s
        };
      return [{
        anchor: i,
        bboxes: [l],
        draw: c(f => {
          this.draw(f, [[new X(0, 0)]], e.zoom, r);
        }, "draw")
      }];
    }
  };
c(Ae, "CircleSymbolizer");
var te = Ae,
  De = class De {
    constructor(e) {
      this.font = new N(e), this.text = new E(e), this.fill = new M(e.fill, "black"), this.background = new M(e.background, "white"), this.padding = new T(e.padding, 0);
    }
    place(e, t, r) {
      let n = this.text.get(e.zoom, r);
      if (!n) return;
      let i = this.font.get(e.zoom, r);
      e.scratch.font = i;
      let s = e.scratch.measureText(n),
        l = s.width,
        o = s.actualBoundingBoxAscent,
        f = s.actualBoundingBoxDescent,
        u = t[0],
        m = new X(t[0][0].x, t[0][0].y),
        d = this.padding.get(e.zoom, r),
        b = {
          minX: m.x - l / 2 - d,
          minY: m.y - o - d,
          maxX: m.x + l / 2 + d,
          maxY: m.y + f + d
        };
      return [{
        anchor: m,
        bboxes: [b],
        draw: c(y => {
          y.globalAlpha = 1, y.fillStyle = this.background.get(e.zoom, r), y.fillRect(-l / 2 - d, -o - d, l + 2 * d, o + f + 2 * d), y.fillStyle = this.fill.get(e.zoom, r), y.font = i, y.fillText(n, -l / 2, 0);
        }, "draw")
      }];
    }
  };
c(De, "ShieldSymbolizer");
var at = De,
  Me = class Me {
    constructor(e) {
      this.list = e;
    }
    place(e, t, r) {
      let n = this.list[0].place(e, t, r);
      if (!n) return;
      let i = n[0],
        s = i.anchor,
        l = i.bboxes[0],
        o = l.maxY - l.minY,
        f = [{
          draw: i.draw,
          translate: {
            x: 0,
            y: 0
          }
        }],
        u = [[new X(t[0][0].x, t[0][0].y + o)]];
      for (let d = 1; d < this.list.length; d++) n = this.list[d].place(e, u, r), n && (i = n[0], l = lt(l, i.bboxes[0]), f.push({
        draw: i.draw,
        translate: {
          x: 0,
          y: o
        }
      }));
      return [{
        anchor: s,
        bboxes: [l],
        draw: c(d => {
          for (let b of f) d.save(), d.translate(b.translate.x, b.translate.y), b.draw(d), d.restore();
        }, "draw")
      }];
    }
  };
c(Me, "FlexSymbolizer");
var st = Me,
  lt = c((a, e) => ({
    minX: Math.min(a.minX, e.minX),
    minY: Math.min(a.minY, e.minY),
    maxX: Math.max(a.maxX, e.maxX),
    maxY: Math.max(a.maxY, e.maxY)
  }), "mergeBbox"),
  Oe = class Oe {
    constructor(e) {
      this.list = e;
    }
    place(e, t, r) {
      let n = this.list[0];
      if (!n) return;
      let i = n.place(e, t, r);
      if (!i) return;
      let s = i[0],
        l = s.anchor,
        o = s.bboxes[0],
        f = [s.draw];
      for (let m = 1; m < this.list.length; m++) {
        if (i = this.list[m].place(e, t, r), !i) return;
        s = i[0], o = lt(o, s.bboxes[0]), f.push(s.draw);
      }
      return [{
        anchor: l,
        bboxes: [o],
        draw: c(m => {
          for (let d of f) d(m);
        }, "draw")
      }];
    }
  };
c(Oe, "GroupSymbolizer");
var re = Oe,
  Re = class Re {
    constructor(e) {
      this.symbolizer = e;
    }
    place(e, t, r) {
      let n = t[0][0],
        i = this.symbolizer.place(e, [[new X(0, 0)]], r);
      if (!i || i.length === 0) return;
      let s = i[0],
        l = s.bboxes[0],
        o = l.maxX - l.minX,
        f = l.maxY - l.minY,
        u = {
          minX: n.x - o / 2,
          maxX: n.x + o / 2,
          minY: n.y - f / 2,
          maxY: n.y + f / 2
        };
      return [{
        anchor: n,
        bboxes: [u],
        draw: c(d => {
          d.translate(-o / 2, f / 2 - l.maxY), s.draw(d, {
            justify: 2
          });
        }, "draw")
      }];
    }
  };
c(Re, "CenteredSymbolizer");
var Te = Re,
  Fe = class Fe {
    constructor(e, t) {
      this.padding = new T(e, 0), this.symbolizer = t;
    }
    place(e, t, r) {
      let n = this.symbolizer.place(e, t, r);
      if (!n || n.length === 0) return;
      let i = this.padding.get(e.zoom, r);
      for (let s of n) for (let l of s.bboxes) l.minX -= i, l.minY -= i, l.maxX += i, l.maxY += i;
      return n;
    }
  };
c(Fe, "Padding");
var ot = Fe,
  je = class je {
    constructor(e) {
      this.font = new N(e), this.text = new E(e), this.fill = new M(e.fill, "black"), this.stroke = new M(e.stroke, "black"), this.width = new T(e.width, 0), this.lineHeight = new T(e.lineHeight, 1), this.letterSpacing = new T(e.letterSpacing, 0), this.maxLineCodeUnits = new T(e.maxLineChars, 15), this.justify = e.justify;
    }
    place(e, t, r) {
      let n = this.text.get(e.zoom, r);
      if (!n) return;
      let i = this.font.get(e.zoom, r);
      e.scratch.font = i;
      let s = this.letterSpacing.get(e.zoom, r),
        l = xe(n, this.maxLineCodeUnits.get(e.zoom, r)),
        o = "",
        f = 0;
      for (let p of l) p.length > f && (f = p.length, o = p);
      let u = e.scratch.measureText(o),
        m = u.width + s * (f - 1),
        d = u.actualBoundingBoxAscent,
        b = u.actualBoundingBoxDescent,
        g = (d + b) * this.lineHeight.get(e.zoom, r),
        y = new X(t[0][0].x, t[0][0].y),
        _ = {
          minX: y.x,
          minY: y.y - d,
          maxX: y.x + m,
          maxY: y.y + b + (l.length - 1) * g
        };
      return [{
        anchor: y,
        bboxes: [_],
        draw: c((p, x) => {
          p.globalAlpha = 1, p.font = i, p.fillStyle = this.fill.get(e.zoom, r);
          let A = this.width.get(e.zoom, r),
            h = 0;
          for (let v of l) {
            let z = 0;
            if (this.justify === 2 || x && x.justify === 2 ? z = (m - p.measureText(v).width) / 2 : (this.justify === 3 || x && x.justify === 3) && (z = m - p.measureText(v).width), A) if (p.lineWidth = A * 2, p.strokeStyle = this.stroke.get(e.zoom, r), s > 0) {
              let w = z;
              for (let S of v) p.strokeText(S, w, h), w += p.measureText(S).width + s;
            } else p.strokeText(v, z, h);
            if (s > 0) {
              let w = z;
              for (let S of v) p.fillText(S, w, h), w += p.measureText(S).width + s;
            } else p.fillText(v, z, h);
            h += g;
          }
        }, "draw")
      }];
    }
  };
c(je, "TextSymbolizer");
var ne = je,
  Xe = class Xe {
    constructor(e) {
      this.centered = new Te(new ne(e));
    }
    place(e, t, r) {
      return this.centered.place(e, t, r);
    }
  };
c(Xe, "CenteredTextSymbolizer");
var I = Xe,
  Ye = class Ye {
    constructor(e, t) {
      var r, n, i;
      this.symbolizer = e, this.offsetX = new T(t.offsetX, 0), this.offsetY = new T(t.offsetY, 0), this.justify = (r = t.justify) != null ? r : void 0, this.placements = (n = t.placements) != null ? n : [2, 6, 8, 4, 1, 3, 5, 7], this.ddValues = (i = t.ddValues) != null ? i : () => ({});
    }
    place(e, t, r) {
      if (r.geomType !== 1) return;
      let n = t[0][0],
        i = this.symbolizer.place(e, [[new X(0, 0)]], r);
      if (!i || i.length === 0) return;
      let s = i[0],
        l = s.bboxes[0],
        o = this.offsetX,
        f = this.offsetY,
        u = this.justify,
        m = this.placements,
        {
          offsetX: d,
          offsetY: b,
          justify: g,
          placements: y
        } = this.ddValues(e.zoom, r) || {};
      d && (o = new T(d, 0)), b && (f = new T(b, 0)), g && (u = g), y && (m = y);
      let _ = o.get(e.zoom, r),
        k = f.get(e.zoom, r),
        p = c((z, w) => ({
          minX: z.x + w.x + l.minX,
          minY: z.y + w.y + l.minY,
          maxX: z.x + w.x + l.maxX,
          maxY: z.y + w.y + l.maxY
        }), "getBbox"),
        x = new X(_, k),
        A,
        h = c(z => {
          z.translate(x.x, x.y), s.draw(z, {
            justify: A
          });
        }, "draw"),
        v = c((z, w) => {
          let S = p(z, w);
          if (!e.index.bboxCollides(S, e.order)) return [{
            anchor: n,
            bboxes: [S],
            draw: h
          }];
        }, "placeLabelInPoint");
      for (let z of m) {
        let w = this.computeXaxisOffset(_, l, z),
          S = this.computeYaxisOffset(k, l, z);
        return A = this.computeJustify(u, z), x = new X(w, S), v(n, x);
      }
    }
    computeXaxisOffset(e, t, r) {
      let n = t.maxX,
        i = n / 2;
      return [1, 5].includes(r) ? e - i : [8, 7, 6].includes(r) ? e - n : e;
    }
    computeYaxisOffset(e, t, r) {
      let n = Math.abs(t.minY),
        i = t.maxY,
        s = (t.minY + t.maxY) / 2;
      return [3, 7].includes(r) ? e - s : [8, 2, 1].includes(r) ? e - i : [6, 4, 5].includes(r) ? e + n : e;
    }
    computeJustify(e, t) {
      return e || ([1, 5].includes(t) ? 2 : [2, 3, 4].includes(t) ? 1 : 3);
    }
  };
c(Ye, "OffsetSymbolizer");
var Pe = Ye,
  Ie = class Ie {
    constructor(e) {
      this.symbolizer = new Pe(new ne(e), e);
    }
    place(e, t, r) {
      return this.symbolizer.place(e, t, r);
    }
  };
c(Ie, "OffsetTextSymbolizer");
var ie = Ie,
  Rt = (r => (r[r.Above = 1] = "Above", r[r.Center = 2] = "Center", r[r.Below = 3] = "Below", r))(Rt || {}),
  Be = class Be {
    constructor(e) {
      var t;
      this.font = new N(e), this.text = new E(e), this.fill = new M(e.fill, "black"), this.stroke = new M(e.stroke, "black"), this.width = new T(e.width, 0), this.offset = new T(e.offset, 0), this.position = (t = e.position) != null ? t : 1, this.maxLabelCodeUnits = new T(e.maxLabelChars, 40), this.repeatDistance = new T(e.repeatDistance, 250);
    }
    place(e, t, r) {
      let n = this.text.get(e.zoom, r);
      if (!n || n.length > this.maxLabelCodeUnits.get(e.zoom, r)) return;
      let i = 20,
        s = r.bbox;
      if (s.maxY - s.minY < i && s.maxX - s.minX < i) return;
      let l = this.font.get(e.zoom, r);
      e.scratch.font = l;
      let o = e.scratch.measureText(n),
        f = o.width,
        u = o.actualBoundingBoxAscent + o.actualBoundingBoxDescent,
        m = this.repeatDistance.get(e.zoom, r);
      e.overzoom > 4 && (m *= 1 << e.overzoom - 4);
      let d = u * 2,
        b = Ue(t, f, m, d);
      if (b.length === 0) return;
      let g = [];
      for (let y of b) {
        let _ = y.end.x - y.start.x,
          k = y.end.y - y.start.y,
          x = Ge(y.start, y.end, f, d / 2).map(h => ({
            minX: h.x - d / 2,
            minY: h.y - d / 2,
            maxX: h.x + d / 2,
            maxY: h.y + d / 2
          })),
          A = c(h => {
            h.globalAlpha = 1, h.rotate(Math.atan2(k, _)), _ < 0 && (h.scale(-1, -1), h.translate(-f, 0));
            let v = 0;
            this.position === 3 ? v += u : this.position === 2 && (v += u / 2), h.translate(0, v - this.offset.get(e.zoom, r)), h.font = l;
            let z = this.width.get(e.zoom, r);
            z && (h.lineWidth = z, h.strokeStyle = this.stroke.get(e.zoom, r), h.strokeText(n, 0, 0)), h.fillStyle = this.fill.get(e.zoom, r), h.fillText(n, 0, 0);
          }, "draw");
        g.push({
          anchor: y.start,
          bboxes: x,
          draw: A,
          deduplicationKey: n,
          deduplicationDistance: m
        });
      }
      return g;
    }
  };
c(Be, "LineLabelSymbolizer");
var $ = Be;
var R = c((a, e) => {
    let t = a[e];
    return typeof t == "string" ? t : "";
  }, "getString"),
  ct = c((a, e) => {
    let t = a[e];
    return typeof t == "number" ? t : 0;
  }, "getNumber"),
  ae = c(a => [{
    dataLayer: "earth",
    symbolizer: new P({
      fill: a.earth
    })
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: c((e, t) => Ee(a.park_a, a.park_b, Math.min(Math.max(e / 12, 12), 0)), "fill")
    }),
    filter: c((e, t) => {
      let r = R(t.props, "pmap:kind");
      return ["allotments", "village_green", "playground"].includes(r);
    }, "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.park_b,
      opacity: .7
    }),
    filter: c((e, t) => {
      let r = R(t.props, "pmap:kind");
      return ["national_park", "park", "cemetery", "protected_area", "nature_reserve", "forest", "golf_course"].includes(r);
    }, "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.hospital
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "hospital", "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.industrial
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "industrial", "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.school
    }),
    filter: c((e, t) => {
      let r = R(t.props, "pmap:kind");
      return ["school", "university", "college"].includes(r);
    }, "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.beach
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "beach", "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.zoo
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "zoo", "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.zoo
    }),
    filter: c((e, t) => {
      let r = R(t.props, "pmap:kind");
      return ["military", "naval_base", "airfield"].includes(r);
    }, "filter")
  }, {
    dataLayer: "natural",
    symbolizer: new P({
      fill: c((e, t) => Ee(a.wood_a, a.wood_b, Math.min(Math.max(e / 12, 12), 0)), "fill")
    }),
    filter: c((e, t) => {
      let r = R(t.props, "pmap:kind");
      return ["wood", "nature_reserve", "forest"].includes(r);
    }, "filter")
  }, {
    dataLayer: "natural",
    symbolizer: new P({
      fill: c((e, t) => Ee(a.scrub_a, a.scrub_b, Math.min(Math.max(e / 12, 12), 0)), "fill")
    }),
    filter: c((e, t) => {
      let r = R(t.props, "pmap:kind");
      return ["scrub", "grassland", "grass"].includes(r);
    }, "filter")
  }, {
    dataLayer: "natural",
    symbolizer: new P({
      fill: a.scrub_b
    }),
    filter: c((e, t) => {
      let r = R(t.props, "pmap:kind");
      return ["scrub", "grassland", "grass"].includes(r);
    }, "filter")
  }, {
    dataLayer: "natural",
    symbolizer: new P({
      fill: a.glacier
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "glacier", "filter")
  }, {
    dataLayer: "natural",
    symbolizer: new P({
      fill: a.sand
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "sand", "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.aerodrome
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "aerodrome", "filter")
  }, {
    dataLayer: "water",
    symbolizer: new P({
      fill: a.water
    })
  }, {
    dataLayer: "transit",
    symbolizer: new O({
      color: a.runway,
      width: c((e, t) => F(1.6, [[11, 0], [13, 4], [19, 30]])(e), "width")
    }),
    filter: c((e, t) => t.props["pmap:kind_detail"] === "runway", "filter")
  }, {
    dataLayer: "transit",
    symbolizer: new O({
      color: a.runway,
      width: c((e, t) => F(1.6, [[14, 0], [14.5, 1], [16, 6]])(e), "width")
    }),
    filter: c((e, t) => t.props["pmap:kind_detail"] === "taxiway", "filter")
  }, {
    dataLayer: "transit",
    symbolizer: new O({
      color: a.pier,
      width: c((e, t) => F(1.6, [[13, 0], [13.5, 0, 5], [21, 16]])(e), "width")
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "pier", "filter")
  }, {
    dataLayer: "physical_line",
    minzoom: 14,
    symbolizer: new O({
      color: a.water,
      width: c((e, t) => F(1.6, [[9, 0], [9.5, 1], [18, 12]])(e), "width")
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "river", "filter")
  }, {
    dataLayer: "physical_line",
    minzoom: 14,
    symbolizer: new O({
      color: a.water,
      width: .5
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "stream", "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.pedestrian
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "pedestrian", "filter")
  }, {
    dataLayer: "landuse",
    symbolizer: new P({
      fill: a.pier
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "pier", "filter")
  }, {
    dataLayer: "buildings",
    symbolizer: new P({
      fill: a.buildings,
      opacity: .5
    })
  }, {
    dataLayer: "roads",
    symbolizer: new O({
      color: a.major,
      width: c((e, t) => F(1.6, [[14, 0], [20, 7]])(e), "width")
    }),
    filter: c((e, t) => {
      let r = R(t.props, "pmap:kind");
      return ["other", "path"].includes(r);
    }, "filter")
  }, {
    dataLayer: "roads",
    symbolizer: new O({
      color: a.major,
      width: c((e, t) => F(1.6, [[13, 0], [18, 8]])(e), "width")
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "minor_road", "filter")
  }, {
    dataLayer: "roads",
    symbolizer: new O({
      color: a.major,
      width: c((e, t) => F(1.6, [[7, 0], [12, 1.2], [15, 3], [18, 13]])(e), "width")
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "medium_road", "filter")
  }, {
    dataLayer: "roads",
    symbolizer: new O({
      color: a.major,
      width: c((e, t) => F(1.6, [[6, 0], [12, 1.6], [15, 3], [18, 13]])(e), "width")
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "major_road", "filter")
  }, {
    dataLayer: "roads",
    symbolizer: new O({
      color: a.major,
      width: c((e, t) => F(1.6, [[3, 0], [6, 1.1], [12, 1.6], [15, 5], [18, 15]])(e), "width")
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "highway", "filter")
  }, {
    dataLayer: "boundaries",
    symbolizer: new O({
      dash: [3, 2],
      color: a.boundaries,
      width: 1
    }),
    filter: c((e, t) => {
      let r = t.props["pmap:min_admin_level"];
      return typeof r == "number" && r <= 2;
    }, "filter")
  }, {
    dataLayer: "transit",
    symbolizer: new O({
      dash: [.3, .75],
      color: a.railway,
      dashWidth: c((e, t) => F(1.6, [[4, 0], [7, .15], [19, 9]])(e), "dashWidth"),
      opacity: .5
    }),
    filter: c((e, t) => t.props["pmap:kind"] === "rail", "filter")
  }, {
    dataLayer: "boundaries",
    symbolizer: new O({
      dash: [3, 2],
      color: a.boundaries,
      width: .5
    }),
    filter: c((e, t) => {
      let r = t.props["pmap:min_admin_level"];
      return typeof r == "number" && r > 2;
    }, "filter")
  }], "paintRules"),
  se = c(a => {
    let e = ["name"];
    return [{
      dataLayer: "roads",
      symbolizer: new $({
        labelProps: e,
        fill: a.roads_label_minor,
        font: "400 12px sans-serif",
        width: 2,
        stroke: a.roads_label_minor_halo
      }),
      minzoom: 16,
      filter: c((t, r) => {
        let n = R(r.props, "pmap:kind");
        return ["minor_road", "other", "path"].includes(n);
      }, "filter")
    }, {
      dataLayer: "roads",
      symbolizer: new $({
        labelProps: e,
        fill: a.roads_label_major,
        font: "400 12px sans-serif",
        width: 2,
        stroke: a.roads_label_major_halo
      }),
      minzoom: 12,
      filter: c((t, r) => {
        let n = R(r.props, "pmap:kind");
        return ["highway", "major_road", "medium_road"].includes(n);
      }, "filter")
    }, {
      dataLayer: "roads",
      symbolizer: new $({
        labelProps: e,
        fill: a.roads_label_major,
        font: "400 12px sans-serif",
        width: 2,
        stroke: a.roads_label_major_halo
      }),
      minzoom: 12,
      filter: c((t, r) => {
        let n = R(r.props, "pmap:kind");
        return ["highway", "major_road", "medium_road"].includes(n);
      }, "filter")
    }, {
      dataLayer: "physical_point",
      symbolizer: new I({
        labelProps: e,
        fill: a.ocean_label,
        lineHeight: 1.5,
        letterSpacing: 1,
        font: c((t, r) => `400 ${Le([[3, 10], [10, 12]])(t)}px sans-serif`, "font"),
        textTransform: "uppercase"
      }),
      filter: c((t, r) => {
        let n = R(r.props, "pmap:kind");
        return ["ocean", "bay", "strait", "fjord"].includes(n);
      }, "filter")
    }, {
      dataLayer: "physical_point",
      symbolizer: new I({
        labelProps: e,
        fill: a.ocean_label,
        lineHeight: 1.5,
        letterSpacing: 1,
        font: c((t, r) => `400 ${Le([[3, 0], [6, 12], [10, 12]])(t)}px sans-serif`, "font")
      }),
      filter: c((t, r) => {
        let n = R(r.props, "pmap:kind");
        return ["sea", "lake", "water"].includes(n);
      }, "filter")
    }, {
      dataLayer: "places",
      symbolizer: new I({
        labelProps: c((t, r) => t < 6 ? ["name:short"] : e, "labelProps"),
        fill: a.state_label,
        stroke: a.state_label_halo,
        width: 1,
        lineHeight: 1.5,
        font: c((t, r) => t < 6 ? "400 16px sans-serif" : "400 12px sans-serif", "font"),
        textTransform: "uppercase"
      }),
      filter: c((t, r) => r.props["pmap:kind"] === "region", "filter")
    }, {
      dataLayer: "places",
      symbolizer: new I({
        labelProps: e,
        fill: a.country_label,
        lineHeight: 1.5,
        font: c((t, r) => (t < 6, "600 12px sans-serif"), "font"),
        textTransform: "uppercase"
      }),
      filter: c((t, r) => r.props["pmap:kind"] === "country", "filter")
    }, {
      dataLayer: "places",
      minzoom: 9,
      symbolizer: new I({
        labelProps: e,
        fill: a.city_label,
        lineHeight: 1.5,
        font: c((t, r) => {
          if (!r) return "400 12px sans-serif";
          let n = r.props["pmap:min_zoom"],
            i = 400;
          n && n <= 5 && (i = 600);
          let s = 12,
            l = r.props["pmap:population_rank"];
          return l && l > 9 && (s = 16), `${i} ${s}px sans-serif`;
        }, "font")
      }),
      sort: c((t, r) => {
        let n = ct(t, "pmap:population_rank"),
          i = ct(r, "pmap:population_rank");
        return n - i;
      }, "sort"),
      filter: c((t, r) => r.props["pmap:kind"] === "locality", "filter")
    }, {
      dataLayer: "places",
      maxzoom: 8,
      symbolizer: new re([new te({
        radius: 2,
        fill: a.city_circle,
        stroke: a.city_circle_stroke,
        width: 1.5
      }), new ie({
        labelProps: e,
        fill: a.city_label,
        stroke: a.city_label_halo,
        width: 1,
        offsetX: 6,
        offsetY: 4.5,
        font: c((t, r) => "400 12px sans-serif", "font")
      })]),
      filter: c((t, r) => r.props["pmap:kind"] === "locality", "filter")
    }];
  }, "labelRules");
var Ft = {
    background: "#cccccc",
    earth: "#e0e0e0",
    park_a: "#cfddd5",
    park_b: "#9cd3b4",
    hospital: "#e4dad9",
    industrial: "#d1dde1",
    school: "#e4ded7",
    wood_a: "#d0ded0",
    wood_b: "#a0d9a0",
    pedestrian: "#e3e0d4",
    scrub_a: "#cedcd7",
    scrub_b: "#99d2bb",
    glacier: "#e7e7e7",
    sand: "#e2e0d7",
    beach: "#e8e4d0",
    aerodrome: "#dadbdf",
    runway: "#e9e9ed",
    water: "#80deea",
    pier: "#e0e0e0",
    zoo: "#c6dcdc",
    military: "#dcdcdc",
    tunnel_other_casing: "#e0e0e0",
    tunnel_minor_casing: "#e0e0e0",
    tunnel_link_casing: "#e0e0e0",
    tunnel_medium_casing: "#e0e0e0",
    tunnel_major_casing: "#e0e0e0",
    tunnel_highway_casing: "#e0e0e0",
    tunnel_other: "#d5d5d5",
    tunnel_minor: "#d5d5d5",
    tunnel_link: "#d5d5d5",
    tunnel_medium: "#d5d5d5",
    tunnel_major: "#d5d5d5",
    tunnel_highway: "#d5d5d5",
    transit_pier: "#e0e0e0",
    buildings: "#cccccc",
    minor_service_casing: "#e0e0e0",
    minor_casing: "#e0e0e0",
    link_casing: "#e0e0e0",
    medium_casing: "#e0e0e0",
    major_casing_late: "#e0e0e0",
    highway_casing_late: "#e0e0e0",
    other: "#ebebeb",
    minor_service: "#ebebeb",
    minor_a: "#ebebeb",
    minor_b: "#ffffff",
    link: "#ffffff",
    medium: "#f5f5f5",
    major_casing_early: "#e0e0e0",
    major: "#ffffff",
    highway_casing_early: "#e0e0e0",
    highway: "#ffffff",
    railway: "#a7b1b3",
    boundaries: "#adadad",
    waterway_label: "#ffffff",
    bridges_other_casing: "#e0e0e0",
    bridges_minor_casing: "#e0e0e0",
    bridges_link_casing: "#e0e0e0",
    bridges_medium_casing: "#e0e0e0",
    bridges_major_casing: "#e0e0e0",
    bridges_highway_casing: "#e0e0e0",
    bridges_other: "#ebebeb",
    bridges_minor: "#ffffff",
    bridges_link: "#ffffff",
    bridges_medium: "#f0eded",
    bridges_major: "#f5f5f5",
    bridges_highway: "#ffffff",
    roads_label_minor: "#91888b",
    roads_label_minor_halo: "#ffffff",
    roads_label_major: "#938a8d",
    roads_label_major_halo: "#ffffff",
    ocean_label: "#ffffff",
    peak_label: "#7e9aa0",
    subplace_label: "#8f8f8f",
    subplace_label_halo: "#e0e0e0",
    city_circle: "#ffffff",
    city_circle_stroke: "#a3a3a3",
    city_label: "#5c5c5c",
    city_label_halo: "#e0e0e0",
    state_label: "#b3b3b3",
    state_label_halo: "#e0e0e0",
    country_label: "#a3a3a3"
  },
  jt = {
    background: "#34373d",
    earth: "#1f1f1f",
    park_a: "#232325",
    park_b: "#232325",
    hospital: "#252424",
    industrial: "#222222",
    school: "#262323",
    wood_a: "#202121",
    wood_b: "#202121",
    pedestrian: "#1e1e1e",
    scrub_a: "#222323",
    scrub_b: "#222323",
    glacier: "#1c1c1c",
    sand: "#212123",
    beach: "#28282a",
    aerodrome: "#1e1e1e",
    runway: "#333333",
    water: "#34373d",
    pier: "#222222",
    zoo: "#222323",
    military: "#242323",
    tunnel_other_casing: "#141414",
    tunnel_minor_casing: "#141414",
    tunnel_link_casing: "#141414",
    tunnel_medium_casing: "#141414",
    tunnel_major_casing: "#141414",
    tunnel_highway_casing: "#141414",
    tunnel_other: "#292929",
    tunnel_minor: "#292929",
    tunnel_link: "#292929",
    tunnel_medium: "#292929",
    tunnel_major: "#292929",
    tunnel_highway: "#292929",
    transit_pier: "#333333",
    buildings: "#111111",
    minor_service_casing: "#1f1f1f",
    minor_casing: "#1f1f1f",
    link_casing: "#1f1f1f",
    medium_casing: "#1f1f1f",
    major_casing_late: "#1f1f1f",
    highway_casing_late: "#1f1f1f",
    other: "#333333",
    minor_service: "#333333",
    minor_a: "#3d3d3d",
    minor_b: "#333333",
    link: "#3d3d3d",
    medium: "#3d3d3d",
    major_casing_early: "#1f1f1f",
    major: "#3d3d3d",
    highway_casing_early: "#1f1f1f",
    highway: "#474747",
    railway: "#000000",
    boundaries: "#5b6374",
    waterway_label: "#717784",
    bridges_other_casing: "#2b2b2b",
    bridges_minor_casing: "#1f1f1f",
    bridges_link_casing: "#1f1f1f",
    bridges_medium_casing: "#1f1f1f",
    bridges_major_casing: "#1f1f1f",
    bridges_highway_casing: "#1f1f1f",
    bridges_other: "#333333",
    bridges_minor: "#333333",
    bridges_link: "#3d3d3d",
    bridges_medium: "#3d3d3d",
    bridges_major: "#3d3d3d",
    bridges_highway: "#474747",
    roads_label_minor: "#525252",
    roads_label_minor_halo: "#1f1f1f",
    roads_label_major: "#666666",
    roads_label_major_halo: "#1f1f1f",
    ocean_label: "#717784",
    peak_label: "#898080",
    subplace_label: "#525252",
    subplace_label_halo: "#1f1f1f",
    city_circle: "#000000",
    city_circle_stroke: "#7a7a7a",
    city_label: "#7a7a7a",
    city_label_halo: "#212121",
    state_label: "#3d3d3d",
    state_label_halo: "#1f1f1f",
    country_label: "#5c5c5c"
  },
  Xt = {
    background: "#ffffff",
    earth: "#ffffff",
    park_a: "#fcfcfc",
    park_b: "#fcfcfc",
    hospital: "#f8f8f8",
    industrial: "#fcfcfc",
    school: "#f8f8f8",
    wood_a: "#fafafa",
    wood_b: "#fafafa",
    pedestrian: "#fdfdfd",
    scrub_a: "#fafafa",
    scrub_b: "#fafafa",
    glacier: "#fcfcfc",
    sand: "#fafafa",
    beach: "#f6f6f6",
    aerodrome: "#fdfdfd",
    runway: "#efefef",
    water: "#dcdcdc",
    pier: "#f5f5f5",
    zoo: "#f7f7f7",
    military: "#fcfcfc",
    tunnel_other_casing: "#d6d6d6",
    tunnel_minor_casing: "#fcfcfc",
    tunnel_link_casing: "#fcfcfc",
    tunnel_medium_casing: "#fcfcfc",
    tunnel_major_casing: "#fcfcfc",
    tunnel_highway_casing: "#fcfcfc",
    tunnel_other: "#d6d6d6",
    tunnel_minor: "#d6d6d6",
    tunnel_link: "#d6d6d6",
    tunnel_medium: "#d6d6d6",
    tunnel_major: "#d6d6d6",
    tunnel_highway: "#d6d6d6",
    transit_pier: "#efefef",
    buildings: "#efefef",
    minor_service_casing: "#ffffff",
    minor_casing: "#ffffff",
    link_casing: "#ffffff",
    medium_casing: "#ffffff",
    major_casing_late: "#ffffff",
    highway_casing_late: "#ffffff",
    other: "#f5f5f5",
    minor_service: "#f5f5f5",
    minor_a: "#ebebeb",
    minor_b: "#f5f5f5",
    link: "#ebebeb",
    medium: "#ebebeb",
    major_casing_early: "#ffffff",
    major: "#ebebeb",
    highway_casing_early: "#ffffff",
    highway: "#ebebeb",
    railway: "#d6d6d6",
    boundaries: "#adadad",
    waterway_label: "#adadad",
    bridges_other_casing: "#ffffff",
    bridges_minor_casing: "#ffffff",
    bridges_link_casing: "#ffffff",
    bridges_medium_casing: "#ffffff",
    bridges_major_casing: "#ffffff",
    bridges_highway_casing: "#ffffff",
    bridges_other: "#f5f5f5",
    bridges_minor: "#f5f5f5",
    bridges_link: "#ebebeb",
    bridges_medium: "#ebebeb",
    bridges_major: "#ebebeb",
    bridges_highway: "#ebebeb",
    roads_label_minor: "#adadad",
    roads_label_minor_halo: "#ffffff",
    roads_label_major: "#999999",
    roads_label_major_halo: "#ffffff",
    ocean_label: "#adadad",
    peak_label: "#adadad",
    subplace_label: "#8f8f8f",
    subplace_label_halo: "#ffffff",
    city_circle: "#ffffff",
    city_circle_stroke: "#adadad",
    city_label: "#5c5c5c",
    city_label_halo: "#ffffff",
    state_label: "#b3b3b3",
    state_label_halo: "#ffffff",
    country_label: "#b8b8b8"
  },
  Yt = {
    background: "#a3a3a3",
    earth: "#cccccc",
    park_a: "#c2c2c2",
    park_b: "#c2c2c2",
    hospital: "#d0d0d0",
    industrial: "#c6c6c6",
    school: "#d0d0d0",
    wood_a: "#c2c2c2",
    wood_b: "#c2c2c2",
    pedestrian: "#c4c4c4",
    scrub_a: "#c2c2c2",
    scrub_b: "#c2c2c2",
    glacier: "#d2d2d2",
    sand: "#d2d2d2",
    beach: "#d2d2d2",
    aerodrome: "#c9c9c9",
    runway: "#f5f5f5",
    water: "#a3a3a3",
    pier: "#b8b8b8",
    zoo: "#c7c7c7",
    military: "#bfbfbf",
    tunnel_other_casing: "#b8b8b8",
    tunnel_minor_casing: "#b8b8b8",
    tunnel_link_casing: "#b8b8b8",
    tunnel_medium_casing: "#b8b8b8",
    tunnel_major_casing: "#b8b8b8",
    tunnel_highway_casing: "#b8b8b8",
    tunnel_other: "#d6d6d6",
    tunnel_minor: "#d6d6d6",
    tunnel_link: "#d6d6d6",
    tunnel_medium: "#d6d6d6",
    tunnel_major: "#d6d6d6",
    tunnel_highway: "#d6d6d6",
    transit_pier: "#b8b8b8",
    buildings: "#e0e0e0",
    minor_service_casing: "#cccccc",
    minor_casing: "#cccccc",
    link_casing: "#cccccc",
    medium_casing: "#cccccc",
    major_casing_late: "#cccccc",
    highway_casing_late: "#cccccc",
    other: "#e0e0e0",
    minor_service: "#e0e0e0",
    minor_a: "#ebebeb",
    minor_b: "#e0e0e0",
    link: "#ebebeb",
    medium: "#ebebeb",
    major_casing_early: "#cccccc",
    major: "#ebebeb",
    highway_casing_early: "#cccccc",
    highway: "#ebebeb",
    railway: "#f5f5f5",
    boundaries: "#5c5c5c",
    waterway_label: "#7a7a7a",
    bridges_other_casing: "#cccccc",
    bridges_minor_casing: "#cccccc",
    bridges_link_casing: "#cccccc",
    bridges_medium_casing: "#cccccc",
    bridges_major_casing: "#cccccc",
    bridges_highway_casing: "#cccccc",
    bridges_other: "#e0e0e0",
    bridges_minor: "#e0e0e0",
    bridges_link: "#ebebeb",
    bridges_medium: "#ebebeb",
    bridges_major: "#ebebeb",
    bridges_highway: "#ebebeb",
    roads_label_minor: "#999999",
    roads_label_minor_halo: "#e0e0e0",
    roads_label_major: "#8f8f8f",
    roads_label_major_halo: "#ebebeb",
    ocean_label: "#7a7a7a",
    peak_label: "#5c5c5c",
    subplace_label: "#7a7a7a",
    subplace_label_halo: "#cccccc",
    city_circle: "#c2c2c2",
    city_circle_stroke: "#7a7a7a",
    city_label: "#474747",
    city_label_halo: "#cccccc",
    state_label: "#999999",
    state_label_halo: "#cccccc",
    country_label: "#858585"
  },
  It = {
    background: "#2b2b2b",
    earth: "#141414",
    park_a: "#181818",
    park_b: "#181818",
    hospital: "#1d1d1d",
    industrial: "#101010",
    school: "#111111",
    wood_a: "#1a1a1a",
    wood_b: "#1a1a1a",
    pedestrian: "#191919",
    scrub_a: "#1c1c1c",
    scrub_b: "#1c1c1c",
    glacier: "#191919",
    sand: "#161616",
    beach: "#1f1f1f",
    aerodrome: "#191919",
    runway: "#323232",
    water: "#333333",
    pier: "#0a0a0a",
    zoo: "#191919",
    military: "#121212",
    tunnel_other_casing: "#101010",
    tunnel_minor_casing: "#101010",
    tunnel_link_casing: "#101010",
    tunnel_medium_casing: "#101010",
    tunnel_major_casing: "#101010",
    tunnel_highway_casing: "#101010",
    tunnel_other: "#292929",
    tunnel_minor: "#292929",
    tunnel_link: "#292929",
    tunnel_medium: "#292929",
    tunnel_major: "#292929",
    tunnel_highway: "#292929",
    transit_pier: "#0a0a0a",
    buildings: "#0a0a0a",
    minor_service_casing: "#141414",
    minor_casing: "#141414",
    link_casing: "#141414",
    medium_casing: "#141414",
    major_casing_late: "#141414",
    highway_casing_late: "#141414",
    other: "#1f1f1f",
    minor_service: "#1f1f1f",
    minor_a: "#292929",
    minor_b: "#1f1f1f",
    link: "#1f1f1f",
    medium: "#292929",
    major_casing_early: "#141414",
    major: "#292929",
    highway_casing_early: "#141414",
    highway: "#292929",
    railway: "#292929",
    boundaries: "#707070",
    waterway_label: "#707070",
    bridges_other_casing: "#141414",
    bridges_minor_casing: "#141414",
    bridges_link_casing: "#141414",
    bridges_medium_casing: "#141414",
    bridges_major_casing: "#141414",
    bridges_highway_casing: "#141414",
    bridges_other: "#1f1f1f",
    bridges_minor: "#1f1f1f",
    bridges_link: "#292929",
    bridges_medium: "#292929",
    bridges_major: "#292929",
    bridges_highway: "#292929",
    roads_label_minor: "#525252",
    roads_label_minor_halo: "#141414",
    roads_label_major: "#5c5c5c",
    roads_label_major_halo: "#141414",
    ocean_label: "#707070",
    peak_label: "#707070",
    subplace_label: "#5c5c5c",
    subplace_label_halo: "#141414",
    city_circle: "#000000",
    city_circle_stroke: "#666666",
    city_label: "#999999",
    city_label_halo: "#141414",
    state_label: "#3d3d3d",
    state_label_halo: "#141414",
    country_label: "#707070"
  },
  oe = {
    light: Ft,
    dark: jt,
    white: Xt,
    grayscale: Yt,
    black: It
  };
import Bt from "@mapbox/point-geometry";
import Et from "rbush";
import J from "@mapbox/point-geometry";
var ce = c((a, e, t) => {
    let r = [];
    for (let n of a) {
      let i = [];
      for (let s of n) i.push(s.clone().mult(e).add(t));
      r.push(i);
    }
    return r;
  }, "transformGeom"),
  le = c((a, e) => {
    let t = 1 << e;
    return a < 0 ? t + a : a >= t ? a % t : a;
  }, "wrap"),
  $e = class $e {
    constructor(e, t, r) {
      this.tileCache = e, this.maxDataLevel = t, this.levelDiff = r;
    }
    dataTilesForBounds(e, t) {
      let r = C(2, e) / C(2, Math.ceil(e)),
        n = [],
        i = 1,
        s = this.tileCache.tileSize;
      if (e < this.levelDiff) i = 1 / (1 << this.levelDiff - e) * r, n.push({
        dataTile: {
          z: 0,
          x: 0,
          y: 0
        },
        origin: new J(0, 0),
        scale: i,
        dim: s * i
      });else if (e <= this.levelDiff + this.maxDataLevel) {
        let l = 1 << this.levelDiff,
          o = 256 * r,
          f = Math.ceil(e) - this.levelDiff,
          u = Math.floor(t.minX / l / o),
          m = Math.floor(t.minY / l / o),
          d = Math.floor(t.maxX / l / o),
          b = Math.floor(t.maxY / l / o);
        for (let g = u; g <= d; g++) for (let y = m; y <= b; y++) {
          let _ = new J(g * l * o, y * l * o);
          n.push({
            dataTile: {
              z: f,
              x: le(g, f),
              y: le(y, f)
            },
            origin: _,
            scale: r,
            dim: s * r
          });
        }
      } else {
        let l = 1 << this.levelDiff;
        i = (1 << Math.ceil(e) - this.maxDataLevel - this.levelDiff) * r;
        let o = Math.floor(t.minX / l / 256 / i),
          f = Math.floor(t.minY / l / 256 / i),
          u = Math.floor(t.maxX / l / 256 / i),
          m = Math.floor(t.maxY / l / 256 / i);
        for (let d = o; d <= u; d++) for (let b = f; b <= m; b++) {
          let g = new J(d * l * 256 * i, b * l * 256 * i);
          n.push({
            dataTile: {
              z: this.maxDataLevel,
              x: le(d, this.maxDataLevel),
              y: le(b, this.maxDataLevel)
            },
            origin: g,
            scale: i,
            dim: s * i
          });
        }
      }
      return n;
    }
    dataTileForDisplayTile(e) {
      let t,
        r = 1,
        n = this.tileCache.tileSize,
        i;
      if (e.z < this.levelDiff) t = {
        z: 0,
        x: 0,
        y: 0
      }, r = 1 / (1 << this.levelDiff - e.z), i = new J(0, 0), n = n * r;else if (e.z <= this.levelDiff + this.maxDataLevel) {
        let s = 1 << this.levelDiff;
        t = {
          z: e.z - this.levelDiff,
          x: Math.floor(e.x / s),
          y: Math.floor(e.y / s)
        }, i = new J(t.x * s * 256, t.y * s * 256);
      } else {
        r = 1 << e.z - this.maxDataLevel - this.levelDiff;
        let s = 1 << this.levelDiff;
        t = {
          z: this.maxDataLevel,
          x: Math.floor(e.x / s / r),
          y: Math.floor(e.y / s / r)
        }, i = new J(t.x * s * r * 256, t.y * s * r * 256), n = n * r;
      }
      return {
        dataTile: t,
        scale: r,
        origin: i,
        dim: n
      };
    }
    getBbox(e, t) {
      return D(this, null, function* () {
        let r = this.dataTilesForBounds(e, t);
        return (yield Promise.all(r.map(i => this.tileCache.get(i.dataTile)))).map((i, s) => {
          let l = r[s];
          return {
            data: i,
            z: e,
            dataTile: l.dataTile,
            scale: l.scale,
            dim: l.dim,
            origin: l.origin
          };
        });
      });
    }
    getDisplayTile(e) {
      return D(this, null, function* () {
        let t = this.dataTileForDisplayTile(e);
        return {
          data: yield this.tileCache.get(t.dataTile),
          z: e.z,
          dataTile: t.dataTile,
          scale: t.scale,
          origin: t.origin,
          dim: t.dim
        };
      });
    }
    queryFeatures(e, t, r, n) {
      let i = Math.round(r),
        s = Math.min(i - this.levelDiff, this.maxDataLevel),
        l = n / (1 << i - s);
      return this.tileCache.queryFeatures(e, t, s, l);
    }
  };
c($e, "View");
var Ne = $e,
  fe = c(a => {
    let e = c(r => {
        let n = r.levelDiff === void 0 ? 1 : r.levelDiff,
          i = r.maxDataZoom || 15,
          s;
        if (typeof r.url == "string") new URL(r.url, "http://example.com").pathname.endsWith(".pmtiles") ? s = new H(r.url, !0) : s = new Q(r.url, !0);else if (r.url) s = new H(r.url, !0);else throw new Error(`Invalid source ${r.url}`);
        let l = new ee(s, 256 * 1 << n);
        return new Ne(l, i, n);
      }, "sourceToViews"),
      t = new Map();
    if (a.sources) for (let r in a.sources) t.set(r, e(a.sources[r]));else t.set("", e(a));
    return t;
  }, "sourcesToViews");
var Nt = c((a, e, t) => {
    let n = e / 256,
      i = Math.floor(t.minX / 256),
      s = Math.floor(t.minY / 256),
      l = Math.floor(t.maxX / 256),
      o = Math.floor(t.maxY / 256),
      f = Math.log2(n),
      u = [];
    for (let m = i; m <= l; m++) {
      let d = m % (1 << a);
      for (let b = s; b <= o; b++) u.push({
        display: Y({
          z: a,
          x: d,
          y: b
        }),
        key: Y({
          z: a - f,
          x: Math.floor(d / n),
          y: Math.floor(b / n)
        })
      });
    }
    return u;
  }, "covering"),
  We = class We {
    constructor(e, t) {
      this.tree = new Et(), this.current = new Map(), this.dim = e, this.maxLabeledTiles = t;
    }
    hasPrefix(e) {
      for (let t of this.current.keys()) if (t.startsWith(e)) return !0;
      return !1;
    }
    has(e) {
      return this.current.has(e);
    }
    size() {
      return this.current.size;
    }
    keys() {
      return this.current.keys();
    }
    searchBbox(e, t) {
      let r = new Set();
      for (let n of this.tree.search(e)) n.indexedLabel.order <= t && r.add(n.indexedLabel);
      return r;
    }
    searchLabel(e, t) {
      let r = new Set();
      for (let n of e.bboxes) for (let i of this.tree.search(n)) i.indexedLabel.order <= t && r.add(i.indexedLabel);
      return r;
    }
    bboxCollides(e, t) {
      for (let r of this.tree.search(e)) if (r.indexedLabel.order <= t) return !0;
      return !1;
    }
    labelCollides(e, t) {
      for (let r of e.bboxes) for (let n of this.tree.search(r)) if (n.indexedLabel.order <= t) return !0;
      return !1;
    }
    deduplicationCollides(e) {
      if (!e.deduplicationKey || !e.deduplicationDistance) return !1;
      let t = e.deduplicationDistance,
        r = {
          minX: e.anchor.x - t,
          minY: e.anchor.y - t,
          maxX: e.anchor.x + t,
          maxY: e.anchor.y + t
        };
      for (let n of this.tree.search(r)) if (n.indexedLabel.deduplicationKey === e.deduplicationKey && n.indexedLabel.anchor.dist(e.anchor) < t) return !0;
      return !1;
    }
    makeEntry(e) {
      this.current.get(e) && console.log("consistency error 1");
      let t = new Set();
      this.current.set(e, t);
    }
    insert(e, t, r) {
      let n = {
          anchor: e.anchor,
          bboxes: e.bboxes,
          draw: e.draw,
          order: t,
          tileKey: r,
          deduplicationKey: e.deduplicationKey,
          deduplicationDistance: e.deduplicationDistance
        },
        i = this.current.get(r);
      if (!i) {
        let o = new Set();
        this.current.set(r, o), i = o;
      }
      i.add(n);
      let s = !1,
        l = !1;
      for (let o of e.bboxes) this.tree.insert({
        minX: o.minX,
        minY: o.minY,
        maxX: o.maxX,
        maxY: o.maxY,
        indexedLabel: n
      }), o.minX < 0 && (s = !0), o.maxX > this.dim && (l = !0);
      if (s || l) {
        let o = s ? this.dim : -this.dim,
          f = [];
        for (let d of e.bboxes) f.push({
          minX: d.minX + o,
          minY: d.minY,
          maxX: d.maxX + o,
          maxY: d.maxY
        });
        let u = {
            anchor: new Bt(e.anchor.x + o, e.anchor.y),
            bboxes: f,
            draw: e.draw,
            order: t,
            tileKey: r
          },
          m = this.current.get(r);
        m && m.add(u);
        for (let d of f) this.tree.insert({
          minX: d.minX,
          minY: d.minY,
          maxX: d.maxX,
          maxY: d.maxY,
          indexedLabel: u
        });
      }
    }
    pruneOrNoop(e) {
      let t = e.split(":"),
        r,
        n = 0,
        i = 0;
      for (let s of this.current.keys()) {
        let l = s.split(":");
        if (l[3] === t[3]) {
          i++;
          let o = Math.sqrt(C(+l[0] - +t[0], 2) + C(+l[1] - +t[1], 2));
          o > n && (n = o, r = s);
        }
        r && i > this.maxLabeledTiles && this.pruneKey(r);
      }
    }
    pruneKey(e) {
      let t = this.current.get(e);
      if (!t) return;
      let r = [];
      for (let n of this.tree.all()) t.has(n.indexedLabel) && r.push(n);
      for (let n of r) this.tree.remove(n);
      this.current.delete(e);
    }
    removeLabel(e) {
      let t = [];
      for (let n of this.tree.all()) e === n.indexedLabel && t.push(n);
      for (let n of t) this.tree.remove(n);
      let r = this.current.get(e.tileKey);
      r && r.delete(e);
    }
  };
c(We, "Index");
var Je = We,
  He = class He {
    constructor(e, t, r, n, i) {
      this.index = new Je(256 * 1 << e, n), this.z = e, this.scratch = t, this.labelRules = r, this.callback = i;
    }
    layout(e) {
      let t = performance.now(),
        r = new Set();
      for (let [i, s] of e) for (let l of s) {
        let o = `${Y(l.dataTile)}:${i}`;
        this.index.has(o) || (this.index.makeEntry(o), r.add(o));
      }
      let n = new Set();
      for (let [i, s] of this.labelRules.entries()) {
        if (s.visible === !1 || s.minzoom && this.z < s.minzoom || s.maxzoom && this.z > s.maxzoom) continue;
        let l = s.dataSource || "",
          o = e.get(l);
        if (o) for (let f of o) {
          let u = `${Y(f.dataTile)}:${l}`;
          if (!r.has(u)) continue;
          let m = f.data.get(s.dataLayer);
          if (m === void 0) continue;
          let d = m;
          s.sort && d.sort((g, y) => s.sort ? s.sort(g.props, y.props) : 0);
          let b = {
            index: this.index,
            zoom: this.z,
            scratch: this.scratch,
            order: i,
            overzoom: this.z - f.dataTile.z
          };
          for (let g of d) {
            if (s.filter && !s.filter(this.z, g)) continue;
            let y = ce(g.geom, f.scale, f.origin),
              _ = s.symbolizer.place(b, y, g);
            if (_) for (let k of _) {
              let p = !1;
              if (!(k.deduplicationKey && this.index.deduplicationCollides(k))) {
                if (this.index.labelCollides(k, 1 / 0)) {
                  if (!this.index.labelCollides(k, i)) {
                    let x = this.index.searchLabel(k, 1 / 0);
                    for (let A of x) {
                      this.index.removeLabel(A);
                      for (let h of A.bboxes) this.findInvalidatedTiles(n, f.dim, h, u);
                    }
                    this.index.insert(k, i, u), p = !0;
                  }
                } else this.index.insert(k, i, u), p = !0;
                if (p) for (let x of k.bboxes) (x.maxX > f.origin.x + f.dim || x.minX < f.origin.x || x.minY < f.origin.y || x.maxY > f.origin.y + f.dim) && this.findInvalidatedTiles(n, f.dim, x, u);
              }
            }
          }
        }
      }
      for (let i of r) this.index.pruneOrNoop(i);
      return n.size > 0 && this.callback && this.callback(n), performance.now() - t;
    }
    findInvalidatedTiles(e, t, r, n) {
      let i = Nt(this.z, t, r);
      for (let s of i) s.key !== n && this.index.hasPrefix(s.key) && e.add(s.display);
    }
    add(e) {
      let t = !0;
      for (let [n, i] of e) for (let s of i) this.index.has(`${Y(s.dataTile)}:${n}`) || (t = !1);
      return t ? 0 : this.layout(e);
    }
  };
c(He, "Labeler");
var V = He,
  Ve = class Ve {
    constructor(e, t, r, n) {
      this.labelers = new Map(), this.scratch = e, this.labelRules = t, this.maxLabeledTiles = r, this.callback = n;
    }
    add(e, t) {
      let r = this.labelers.get(e);
      return r || (r = new V(e, this.scratch, this.labelRules, this.maxLabeledTiles, this.callback), this.labelers.set(e, r)), r.add(t);
    }
    getIndex(e) {
      let t = this.labelers.get(e);
      if (t) return t.index;
    }
  };
c(Ve, "Labelers");
var Z = Ve;
import $t from "@mapbox/point-geometry";
function ue(a, e, t, r, n, i, s, l, o) {
  let f = performance.now();
  a.save(), a.miterLimit = 2;
  for (let u of n) {
    if (u.minzoom && e < u.minzoom || u.maxzoom && e > u.maxzoom) continue;
    let m = t.get(u.dataSource || "");
    if (m) for (let d of m) {
      let b = d.data.get(u.dataLayer);
      if (b === void 0) continue;
      u.symbolizer.before && u.symbolizer.before(a, d.z);
      let g = d.origin,
        y = d.dim,
        _ = d.scale;
      if (a.save(), l) {
        a.beginPath();
        let k = Math.max(g.x - s.x, i.minX - s.x),
          p = Math.max(g.y - s.y, i.minY - s.y),
          x = Math.min(g.x - s.x + y, i.maxX - s.x),
          A = Math.min(g.y - s.y + y, i.maxY - s.y);
        a.rect(k, p, x - k, A - p), a.clip();
      }
      a.translate(g.x - s.x, g.y - s.y);
      for (let k of b) {
        let p = k.geom,
          x = k.bbox;
        x.maxX * _ + g.x < i.minX || x.minX * _ + g.x > i.maxX || x.minY * _ + g.y > i.maxY || x.maxY * _ + g.y < i.minY || u.filter && !u.filter(d.z, k) || (_ !== 1 && (p = ce(p, _, new $t(0, 0))), u.symbolizer.draw(a, p, d.z, k));
      }
      a.restore();
    }
  }
  if (l && (a.beginPath(), a.rect(i.minX - s.x, i.minY - s.y, i.maxX - i.minX, i.maxY - i.minY), a.clip()), r) {
    let u = r.searchBbox(i, 1 / 0);
    for (let m of u) if (a.save(), a.translate(m.anchor.x - s.x, m.anchor.y - s.y), m.draw(a), a.restore(), o) {
      a.lineWidth = .5, a.strokeStyle = o, a.fillStyle = o, a.globalAlpha = 1, a.fillRect(m.anchor.x - s.x - 2, m.anchor.y - s.y - 2, 4, 4);
      for (let d of m.bboxes) a.strokeRect(d.minX - s.x, d.minY - s.y, d.maxX - d.minX, d.maxY - d.minY);
    }
  }
  return a.restore(), performance.now() - f;
}
c(ue, "paint");
var K = 6378137,
  ft = 85.0511287798,
  j = K * Math.PI,
  mt = c(a => {
    let e = Math.PI / 180,
      t = Math.max(Math.min(ft, a.y), -ft),
      r = Math.sin(t * e);
    return new B(K * a.x * e, K * Math.log((1 + r) / (1 - r)) / 2);
  }, "project"),
  Jt = c(a => {
    let e = 180 / Math.PI;
    return {
      lat: (2 * Math.atan(Math.exp(a.y / K)) - Math.PI / 2) * e,
      lng: a.x * e / K
    };
  }, "unproject"),
  Wt = c((a, e) => t => {
    let r = mt(t);
    return new B((r.x + j) / (j * 2), 1 - (r.y + j) / (j * 2)).mult(C(2, e) * 256).sub(a);
  }, "instancedProject"),
  Ht = c((a, e) => t => {
    let r = new B(t.x, t.y).add(a).div(C(2, e) * 256),
      n = new B(r.x * (j * 2) - j, (1 - r.y) * (j * 2) - j);
    return Jt(n);
  }, "instancedUnproject"),
  ut = c((a, e) => {
    let t = e * (360 / a);
    return Math.log2(t / 256);
  }, "getZoom"),
  Ze = class Ze {
    constructor(e) {
      if (e.theme) {
        let t = oe[e.theme];
        this.paintRules = ae(t), this.labelRules = se(t), this.backgroundColor = t.background;
      } else this.paintRules = e.paintRules || [], this.labelRules = e.labelRules || [], this.backgroundColor = e.backgroundColor;
      this.views = fe(e), this.debug = e.debug || "";
    }
    drawContext(e, t, r, n, i) {
      return D(this, null, function* () {
        let s = mt(n),
          o = new B((s.x + j) / (j * 2), 1 - (s.y + j) / (j * 2)).clone().mult(C(2, i) * 256).sub(new B(t / 2, r / 2)),
          f = {
            minX: o.x,
            minY: o.y,
            maxX: o.x + t,
            maxY: o.y + r
          },
          u = [];
        for (let [p, x] of this.views) {
          let A = x.getBbox(i, f);
          u.push({
            key: p,
            promise: A
          });
        }
        let m = yield Promise.all(u.map(p => p.promise.then(x => ({
            status: "fulfilled",
            value: x,
            key: p.key
          }), x => ({
            status: "rejected",
            value: [],
            reason: x,
            key: p.key
          })))),
          d = new Map();
        for (let p of m) p.status === "fulfilled" && d.set(p.key, p.value);
        let b = performance.now(),
          g = new V(i, e, this.labelRules, 16, void 0),
          y = g.add(d);
        this.backgroundColor && (e.save(), e.fillStyle = this.backgroundColor, e.fillRect(0, 0, t, r), e.restore());
        let _ = this.paintRules,
          k = ue(e, i, d, g.index, _, f, o, !0, this.debug);
        if (this.debug) {
          e.save(), e.translate(-o.x, -o.y), e.strokeStyle = this.debug, e.fillStyle = this.debug, e.font = "12px sans-serif";
          let p = 0;
          for (let [x, A] of d) {
            for (let h of A) {
              e.strokeRect(h.origin.x, h.origin.y, h.dim, h.dim);
              let v = h.dataTile;
              e.fillText(`${x + (x ? " " : "") + v.z} ${v.x} ${v.y}`, h.origin.x + 4, h.origin.y + 14 * (1 + p));
            }
            p++;
          }
          e.restore();
        }
        return {
          elapsed: performance.now() - b,
          project: Wt(o, i),
          unproject: Ht(o, i)
        };
      });
    }
    drawCanvas(i, s, l) {
      return D(this, arguments, function* (e, t, r, n = {}) {
        let o = window.devicePixelRatio,
          f = e.clientWidth,
          u = e.clientHeight;
        e.width === f * o && e.height === u * o || (e.width = f * o, e.height = u * o), n.lang && (e.lang = n.lang);
        let m = e.getContext("2d");
        if (!m) {
          console.error("Failed to initialize canvas2d context.");
          return;
        }
        return m.setTransform(o, 0, 0, o, 0, 0), this.drawContext(m, f, u, t, r);
      });
    }
    drawContextBounds(e, t, r, n, i) {
      return D(this, null, function* () {
        let s = r.x - t.x,
          l = new B((t.x + r.x) / 2, (t.y + r.y) / 2);
        return this.drawContext(e, n, i, l, ut(s, n));
      });
    }
    drawCanvasBounds(s, l, o, f) {
      return D(this, arguments, function* (e, t, r, n, i = {}) {
        let u = r.x - t.x,
          m = new B((t.x + r.x) / 2, (t.y + r.y) / 2);
        return this.drawCanvas(e, m, ut(u, n), i);
      });
    }
  };
c(Ze, "Static");
var dt = Ze;
import Vt from "@mapbox/point-geometry";
var Zt = c(a => new Promise(e => {
    setTimeout(() => {
      e();
    }, a);
  }), "timer"),
  Kt = c(a => a.then(e => ({
    status: "fulfilled",
    value: e
  }), e => ({
    status: "rejected",
    reason: e
  })), "reflect"),
  Pn = c((a = {}) => {
    let t = class t extends L.GridLayer {
      constructor(n = {}) {
        if (n.noWrap && !n.bounds && (n.bounds = [[-90, -180], [90, 180]]), n.attribution == null && (n.attribution = '<a href="https://protomaps.com">Protomaps</a> \xA9 <a href="https://openstreetmap.org/copyright">OpenStreetMap</a>'), super(n), n.theme) {
          let s = oe[n.theme];
          this.paintRules = ae(s), this.labelRules = se(s), this.backgroundColor = s.background;
        } else this.paintRules = n.paintRules || [], this.labelRules = n.labelRules || [], this.backgroundColor = n.backgroundColor;
        this.lastRequestedZ = void 0, this.tasks = n.tasks || [], this.views = fe(n), this.debug = n.debug;
        let i = document.createElement("canvas").getContext("2d");
        this.scratch = i, this.onTilesInvalidated = s => {
          for (let l of s) this.rerenderTile(l);
        }, this.labelers = new Z(this.scratch, this.labelRules, 16, this.onTilesInvalidated), this.tileSize = 256 * window.devicePixelRatio, this.tileDelay = n.tileDelay || 3, this.lang = n.lang;
      }
      renderTile(n, i, s, l = () => {}) {
        return D(this, null, function* () {
          this.lastRequestedZ = n.z;
          let o = [];
          for (let [w, S] of this.views) {
            let de = S.getDisplayTile(n);
            o.push({
              key: w,
              promise: de
            });
          }
          let f = yield Promise.all(o.map(w => w.promise.then(S => ({
              status: "fulfilled",
              value: S,
              key: w.key
            }), S => ({
              status: "rejected",
              reason: S,
              key: w.key
            })))),
            u = new Map();
          for (let w of f) w.status === "fulfilled" ? u.set(w.key, [w.value]) : w.reason.name === "AbortError" || console.error(w.reason);
          if (i.key !== s || this.lastRequestedZ !== n.z || (yield Promise.all(this.tasks.map(Kt)), i.key !== s) || this.lastRequestedZ !== n.z) return;
          let m = this.labelers.add(n.z, u);
          if (i.key !== s || this.lastRequestedZ !== n.z) return;
          let d = this.labelers.getIndex(n.z);
          if (!this._map) return;
          let b = this._map.getCenter().wrap(),
            g = this._getTiledPixelBounds(b),
            _ = this._pxBoundsToTileRange(g).getCenter(),
            k = n.distanceTo(_) * this.tileDelay;
          if (yield Zt(k), i.key !== s || this.lastRequestedZ !== n.z) return;
          let p = 16,
            x = {
              minX: 256 * n.x - p,
              minY: 256 * n.y - p,
              maxX: 256 * (n.x + 1) + p,
              maxY: 256 * (n.y + 1) + p
            },
            A = new Vt(256 * n.x, 256 * n.y);
          i.width = this.tileSize, i.height = this.tileSize;
          let h = i.getContext("2d");
          if (!h) {
            console.error("Failed to get Canvas context");
            return;
          }
          h.setTransform(this.tileSize / 256, 0, 0, this.tileSize / 256, 0, 0), h.clearRect(0, 0, 256, 256), this.backgroundColor && (h.save(), h.fillStyle = this.backgroundColor, h.fillRect(0, 0, 256, 256), h.restore());
          let v = 0,
            z = this.paintRules;
          if (v = ue(h, n.z, u, this.xray ? null : d, z, x, A, !1, this.debug), this.debug) {
            h.save(), h.fillStyle = this.debug, h.font = "600 12px sans-serif", h.fillText(`${n.z} ${n.x} ${n.y}`, 4, 14), h.font = "12px sans-serif";
            let w = 28;
            for (let [S, de] of u) {
              let me = de[0].dataTile;
              h.fillText(`${S + (S ? " " : "") + me.z} ${me.x} ${me.y}`, 4, w), w += 14;
            }
            h.font = "600 10px sans-serif", v > 8 && (h.fillText(`${v.toFixed()} ms paint`, 4, w), w += 14), m > 8 && h.fillText(`${m.toFixed()} ms layout`, 4, w), h.strokeStyle = this.debug, h.lineWidth = .5, h.beginPath(), h.moveTo(0, 0), h.lineTo(0, 256), h.stroke(), h.lineWidth = .5, h.beginPath(), h.moveTo(0, 0), h.lineTo(256, 0), h.stroke(), h.restore();
          }
          l();
        });
      }
      rerenderTile(n) {
        for (let i in this._tiles) {
          let s = this._wrapCoords(this._keyToTileCoords(i));
          n === this._tileCoordsToKey(s) && this.renderTile(s, this._tiles[i].el, n);
        }
      }
      queryTileFeaturesDebug(n, i, s = 16) {
        let l = new Map();
        for (let [o, f] of this.views) l.set(o, f.queryFeatures(n, i, this._map.getZoom(), s));
        return l;
      }
      clearLayout() {
        this.labelers = new Z(this.scratch, this.labelRules, 16, this.onTilesInvalidated);
      }
      rerenderTiles() {
        for (let n in this._tiles) {
          let i = this._wrapCoords(this._keyToTileCoords(n)),
            s = this._tileCoordsToKey(i);
          this.renderTile(i, this._tiles[n].el, s);
        }
      }
      createTile(n, i) {
        let s = L.DomUtil.create("canvas", "leaflet-tile");
        s.lang = this.lang;
        let l = this._tileCoordsToKey(n);
        return s.key = l, this.renderTile(n, s, l, () => {
          i(void 0, s);
        }), s;
      }
      _removeTile(n) {
        let i = this._tiles[n];
        i && (i.el.removed = !0, i.el.key = void 0, L.DomUtil.removeClass(i.el, "leaflet-tile-loaded"), i.el.width = i.el.height = 0, L.DomUtil.remove(i.el), delete this._tiles[n], this.fire("tileunload", {
          tile: i.el,
          coords: this._keyToTileCoords(n)
        }));
      }
    };
    c(t, "LeafletLayer");
    let e = t;
    return new e(a);
  }, "leafletLayer");
import qt from "potpack";
var Sn = c((a, e, t) => {
    let r = new FontFace(a, `url(${e})`, {
      weight: t
    });
    return document.fonts.add(r), r.load();
  }, "Font"),
  ht = c(a => D(void 0, null, function* () {
    return new Promise((e, t) => {
      let r = new Image();
      r.onload = () => e(r), r.onerror = () => t("Invalid SVG"), r.src = a;
    });
  }), "mkimg"),
  Ut = `
<svg width="20px" height="20px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <rect width="50" height="50" fill="#cccccc"/>
    <g transform="translate(5,5)">
        <path fill="none" stroke="#666666" stroke-width="7" d="m11,12a8.5,8 0 1,1 17,0q0,4-4,6t-4.5,4.5-.4,4v.2m0,3v7"/>
    </g>
</svg>
`,
  Ke = class Ke {
    constructor(e) {
      this.src = e, this.canvas = document.createElement("canvas"), this.mapping = new Map(), this.missingBox = {
        x: 0,
        y: 0,
        w: 0,
        h: 0
      };
    }
    load() {
      return D(this, null, function* () {
        let e = this.src,
          t = window.devicePixelRatio;
        e.endsWith(".html") && (e = yield (yield fetch(e)).text());
        let r = new window.DOMParser().parseFromString(e, "text/html"),
          n = Array.from(r.body.children),
          i = yield ht(`data:image/svg+xml;base64,${btoa(Ut)}`),
          s = [{
            w: i.width * t,
            h: i.height * t,
            img: i,
            id: ""
          }],
          l = new XMLSerializer();
        for (let u of n) {
          let d = `data:image/svg+xml;base64,${btoa(l.serializeToString(u))}`,
            b = yield ht(d);
          s.push({
            w: b.width * t,
            h: b.height * t,
            img: b,
            id: u.id
          });
        }
        let o = qt(s);
        this.canvas.width = o.w, this.canvas.height = o.h;
        let f = this.canvas.getContext("2d");
        if (f) for (let u of s) u.x !== void 0 && u.y !== void 0 && (f.drawImage(u.img, u.x, u.y, u.w, u.h), u.id ? this.mapping.set(u.id, {
          x: u.x,
          y: u.y,
          w: u.w,
          h: u.h
        }) : this.missingBox = {
          x: u.x,
          y: u.y,
          w: u.w,
          h: u.h
        });
        return this;
      });
    }
    get(e) {
      let t = this.mapping.get(e);
      return t || (t = this.missingBox), t;
    }
  };
c(Ke, "Sheet");
var bt = Ke;
export { Te as CenteredSymbolizer, I as CenteredTextSymbolizer, te as CircleSymbolizer, st as FlexSymbolizer, Sn as Font, rt as GeomType, re as GroupSymbolizer, it as IconSymbolizer, Je as Index, St as Justify, V as Labeler, Z as Labelers, Rt as LineLabelPlacement, $ as LineLabelSymbolizer, O as LineSymbolizer, Pe as OffsetSymbolizer, ie as OffsetTextSymbolizer, ot as Padding, H as PmtilesSource, P as PolygonSymbolizer, bt as Sheet, at as ShieldSymbolizer, dt as Static, At as TextPlacements, ne as TextSymbolizer, ee as TileCache, Ne as View, Q as ZxySource, Tr as arr, Nt as covering, zr as createPattern, F as exp, ut as getZoom, Pt as isCcw, tt as isInRing, se as labelRules, Pn as leafletLayer, Le as linear, ue as paint, ae as paintRules, Lt as pointInPolygon, Ct as pointMinDistToLines, vt as pointMinDistToPoints, fe as sourcesToViews, Pr as step, Y as toIndex, ce as transformGeom, le as wrap };
