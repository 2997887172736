{
  "daytime": {
    "earlier": "AM",
    "later": "PM"
  },
  "login": {
    "title": " Iniciar Sessió",
    "text": "Iniciar sessió al teu compte",
    "button": "Iniciar Sessió",
    "stressLevel": "Stress Level",
    "errorDialog": {
      "message": "Usuari o contrasenya incorrectes",
      "accept": "D'acord"
    },
    "placeholder": {
      "username": "Si us plau introdueix usuari",
      "password": "Si us plau introdueix contrasenya"
    }
  },
  "sidebar": {
    "bulletins": "Avalanche.report",
    "dangerSources": "Danger Sources",
    "observations": "Observacions",
    "qfa": "QFA",
    "modelling": "Modelització",
    "modellingForecast": "Predicció",
    "modellingZamg": "ZAMG multi model",
    "modellingZamgECMWF": "ZAMG ECMWF-EPS",
    "modellingZamgCLAEF": "ZAMG CLAEF-EPS",
    "modellingZamgMeteogram": "Meteograma GeoSphere",
    "modellingSnowpack": "SNOWPACK model",
    "modellingSnowpackMeteo": "SNOWPACK modelled meteo",
    "weatherbox": "Weatherbox",
    "awsome": "AWSOME",
    "snowpack": "SNOWPACK",
    "geosphere": "GeoSphere"
  },
  "menu": {
    "logout": "Surt",
    "language": "Idioma",
    "en": "Anglès",
    "de": "Alemany",
    "fr": "Francès",
    "it": "Italià",
    "es": "Castellà",
    "ca": "Català",
    "oc": "Aranès",
    "fullscreen": "Pantalla completa",
    "settings": "Configuració",
    "statistics": "Estadístiques",
    "admin": "Admin",
    "region": "Regió",
    "education": "Educació"
  },
  "matrix": {
    "label": {
      "snowpackStability": "Avaluació de l'estabilitat",
      "frequency": "Freqüència",
      "avalancheSize": "Mida d'allaus",
      "dangerRating": "Grau de perill",
      "override": "(modifica el grau de perill)",
      "matrix": "Matriu",
      "referToFairStability": "Referir-se a una estabilitat regular",
      "referToPoorStability": "Referir-se a una estabilitat pobre",
      "dangerLevel1": "Grau de perill 1 (Feble)"
    }
  },
  "dangerSources": {
    "status": {
      "missing": "falta",
      "available": "available",
      "forecast": "forecast",
      "analysis": "analysis",
      "loading": "Loading danger sources ...",
      "saveError": "Danger source could not be saved!",
      "loadDangerSourcesError": "Danger sources could not be loaded!",
      "loadVariantsError": "Danger source variants could not be loaded!"
    },
    "variantStatus": {
      "active": "active",
      "dormant": "dormant",
      "inactive": "inactive"
    },
    "tooltip": {
      "read": "Read danger sources",
      "edit": "Edit danger sources"
    },
    "table": {
      "loadMore": "Load {{count}} more days",
      "title": {
        "forecast": "Predicció",
        "analysis": "Analysis"
      }
    },
    "create": {
      "variantStatus": "Status",
      "variantEditable": "This variant can be edited.",
      "variantNotEditable": "This variant can not be edited.",
      "noVariantsForSelectedDay": "No variants defined for this date.",
      "tooltip": {
        "editDangerSource": "Edit danger source",
        "loadVariants": "Load variants from the day before",
        "edit": "Editar micro-regions",
        "copy": "Copy variant",
        "delete": "Delete variant",
        "compare": "Compare variant",
        "forecast": "Compare with forecast"
      },
      "createDangerSource": "Create danger source",
      "createVariant": "Create new variant",
      "noDangerSources": "No danger sources",
      "title": {
        "avalanche": "Allau",
        "matrix": "Matriu",
        "characteristics": "Característiques",
        "comment": "Comentari"
      },
      "label": {
        "glidingSnowActivity": "Gliding snow activity",
        "snowHeight": "Snow height",
        "zeroDegreeIsotherm": "0°C isotherm",
        "looseSnowMoisture": "Loose snow moisture",
        "looseSnowGrainShape": "Loose snow grain shape",
        "slabGrainShape": "Slab grain shape",
        "slabThickness": "Slab thickness",
        "slabHandHardness": "Slab hand hardness",
        "slabHardnessProfile": "Slab hardness",
        "slabEnergyTransferPotential": "Slab energy transfer potential",
        "slabDistribution": "Slab distribution",
        "weakLayerGrainShape": "Weak layer grain shape",
        "weakLayerGrainSize": "Weak layer grain size",
        "weakLayerPersistent": "Weak layer persistent",
        "weakLayerThickness": "Weak layer thickness",
        "weakLayerStrength": "Weak layer strength",
        "weakLayerWet": "Weak layer wet",
        "weakLayerCrustAbove": "Weak layer crust above",
        "weakLayerCrustBelow": "Weak layer crust below",
        "weakLayerPosition": "Weak layer position",
        "weakLayerCreation": "Weak layer creation",
        "weakLayerDistribution": "Weak layer distribution",
        "dangerSpotRecognizability": "Danger spot recognizability",
        "remoteTriggering": "Remote triggering",
        "hasDaytimeDependency": "Dependència de l'hora",
        "dangerIncreaseWithElevation": "Danger increase with elevation",
        "runoutIntoGreen": "Runout into green",
        "highestDangerAspect": "Highest danger aspect",
        "dangerPeak": "Danger peak",
        "slopeGradient": "Gradient de pendent",
        "naturalRelease": "Natural release",
        "terrainTypes": "Terrain types",
        "dangerSigns": "Danger signs",
        "aspects": "Orientacions"
      },
      "editVariantDialog": {
        "save": "Desar",
        "cancel": "Cancel·lar"
      },
      "deleteVariantDialog": {
        "message": "Do you want to delete this variant?",
        "accept": "Si",
        "reject": "No"
      },
      "noRegionDialog": {
        "message": "At least one micro-region has to be yours.",
        "accept": "D'acord"
      },
      "discardDialog": {
        "message": "Vols descartar els canvis?",
        "accept": "Si",
        "reject": "No"
      },
      "saveErrorDialog": {
        "message": "There was an error saving the danger sources!",
        "accept": "D'acord"
      },
      "loadDialog": {
        "message": "Do you want to load all danger source variants from the day before?",
        "accept": "Si",
        "reject": "No"
      }
    }
  },
  "avalancheType": {
    "title": "Tipus d'allau",
    "label": {
      "slab": "SLAB",
      "loose": "LOOSE",
      "glide": "GLIDE"
    },
    "tooltip": {
      "slab": "Slab avalanche",
      "loose": "Loose snow avalanche",
      "glide": "Glide snow avalanche"
    }
  },
  "elevation": {
    "title": "Altitud",
    "high": "Per sota",
    "low": "Per sobre",
    "treeline": "Límit del bosc"
  },
  "bulletins": {
    "title": "Avalanche.report",
    "tooltip": {
      "edit": "Edit bulletin",
      "read": "Read bulletin",
      "copy": "Copiar el butlletí",
      "paste": "Enganxar el butlletí",
      "cancel": "Cancel·lar la còpia",
      "update": "Editar",
      "submit": "Enviar",
      "submitUpdate": "Enviar actualizacion",
      "publishChange": "Publicar ara (sense missatges)",
      "publishUpdate": "Publicar ara",
      "caaml": "Mostrar CAAML",
      "json": "Mostrar JSON",
      "create": "Crear butlletí",
      "preview": "Previsualitzar el butlletí (PDF)",
      "check": "Comprovar el butlletí",
      "info": "Mostrar l'estat de publicació",
      "publishAll": "Publicar totes les regions",
      "mediaFile": "Carregar un fitxer multimèdia"
    },
    "status": {
      "submittedInfo": "Serà publicat a les 5PM",
      "resubmittedInfo": "Serà publicat a les 8AM",
      "publishedInfo": "Ha estat publicat",
      "noPublicationInfo": "NO serà publicat automàticament",
      "loadingPreview": "S'està creant la previsualització de PDF ...",
      "loading": "Butlletí carregant-se ...",
      "loadMoreBulletins": "Load {{count}} more bulletins",
      "publishing": "Butlletí publicant-se ...",
      "submitting": "Butlletí enviant-se ...",
      "saveError": "Bulletin could not be saved!",
      "loadBulletinsError": "Bulletins could not be loaded!"
    },
    "create": {
      "tooltip": {
        "edit": "Editar micro-regions",
        "copy": "Copiar regió",
        "delete": "Esborrar regió",
        "undo": "Undo last change in region",
        "redo": "Redo change in region",
        "createRegion": "Crear regió",
        "cancel": "Cancel·lar",
        "acceptSuggestion": "Acceptar les regions proposades",
        "rejectSuggestion": "No acceptar les regions proposades",
        "saveChanges": "Guardar els canvis",
        "discardChanges": "Descartar els canvis",
        "incompleteAvalancheProblem": "Problema d'allaus incomplet",
        "saveBulletin": "Guardar el butlletí",
        "discardBulletin": "Descartar els canvis al butlletí",
        "loadBulletin": "Carregar el butlletí d'ahir",
        "uploadJsonBulletin": "Carregar el butlletí en format JSON",
        "downloadJsonBulletin": "Descarregar el butlletí en format JSON",
        "daytimeDependency": "Dependència de l'hora",
        "treeline": "Límit del bosc",
        "tendency": {
          "decreasing": "El perill d'allaus disminueix",
          "steady": "El perill d'allaus roman estable",
          "increasing": "El perill d'allaus augmenta",
          "danger": "Perill d'allaus"
        },
        "textcat": {
          "edit": "Editar",
          "copy": "Copiar",
          "paste": "Enganxar",
          "example": "Carregar text d'exemple",
          "delete": "Esborrar"
        },
        "moveUpAvalancheProblem": "Moure el problema d'allaus cap amunt",
        "moveDownAvalancheProblem": "Moure el problema d'allaus cap avall",
        "dangerRatingDirectionUp": "Cap amunt",
        "dangerRatingDirectionDown": "Cap avall"
      },
      "save": "Desar",
      "discard": "Descartar",
      "back": "Enrera",
      "createAggregatedRegion": "Nova regió",
      "pasteAggregatedRegion": "Enganxar la regió",
      "pendingSuggestions": "Suggeriments",
      "rejectSuggestion": "Rebutjar",
      "acceptSuggestion": "Acceptar",
      "cancelCopyBulletin": "Cancel·lar",
      "createAvalancheProblem": "Nou problema",
      "ownRegions": "Regions pròpies",
      "foreignRegions": "Regions alienes",
      "externalRegions": "Regions externes",
      "mapSelectRegion": "Seleccionar regions al mapa",
      "statusHeader": "Estat del butlletí",
      "suggestions": "Suggeriments",
      "noBulletinsForSelectedDay": "Cap regió definida per aquesta data.",
      "autosave": "Gaurdat automàticament",
      "regionEditable": "Aquesta regió pot editar-se",
      "regionNotEditable": "Aquesta regió no pot editar-se",
      "regionLocked": "Aquesta regió està bloquejada",
      "changeDangerRatingElevation": "Extendre el perill d'allaus",
      "mapTop": "Mapa a sobre",
      "mapLeft": "Mapa al costat",
      "showNotes": "Mostrar notes",
      "hideNotes": "Amagar notes",
      "incompleteAvalancheProblem": "Incomplete",
      "label": {
        "importantObservations": "Important observations",
        "avalancheProblems": "Problema d'allaus",
        "aspects": "Orientacions",
        "highlights": "Alerta especial",
        "avActivityHighlights": "Titular de la situació d'allaus",
        "avActivityComment": "Descripció del perill d'allaus",
        "snowpackStructureComment": "Descripció de l'estructura del mantell",
        "dangerPatterns": "Patrons de perill",
        "tendencyComment": "Descripció de la tendència",
        "notes": "Notes"
      },
      "copyRegion": {
        "label": "Copiar regió d'alerta a un altre butlletí",
        "select": "Enganxar a ...",
        "currentBulletin": "Aquest butlletí"
      },
      "title": {
        "avalancheProblem": "Problemes d'allaus",
        "dangerDescription": "Descripció del perill d'allaus",
        "forenoon": "Matí",
        "afternoon": "Tarda",
        "snowpackStructure": "Estructura del mantell",
        "tendency": "Tendència",
        "showTranslations": "-mostra traduccions",
        "hideTranslations": "Amaga traduccions"
      },
      "placeholder": {
        "notes": "Escriu aquí..."
      },
      "pmDialog": {
        "noIFrameSupport": "El teu navegador no admet Iframes!"
      },
      "discardDialog": {
        "message": "Vols descartar els canvis?",
        "accept": "Si",
        "reject": "No"
      },
      "deleteAggregatedRegionDialog": {
        "message": "Vols esborrar aquesta regió?",
        "accept": "Si",
        "reject": "No"
      },
      "noRegionDialog": {
        "message": "At least one micro-region has to be yours.",
        "accept": "Ok"
      },
      "loadDialog": {
        "message": "Vols carregar els butlletins d'ahir?",
        "accept": "Si",
        "reject": "No"
      },
      "loadAutoSaveDialog": {
        "message": "Vols carregar els butlletins guardats automàticament?",
        "accept": "Si",
        "reject": "No"
      },
      "saveErrorDialog": {
        "message": "Hi ha hagut un error guardant els butlletins!",
        "accept": "D'acord"
      },
      "changeErrorDialog": {
        "message": "Hi ha hagut un error canviant els butlletins!",
        "accept": "D'acord"
      },
      "loadingErrorDialog": {
        "message": "Hi ha hagut un error llegint els butlletins!",
        "accept": "D'acord"
      },
      "loadingJsonFileErrorDialog": {
        "message": "S'ha produït un error en carregar els butlletins des del fitxer JSON!",
        "accept": "D'acord"
      },
      "avalancheProblemErrorDialog": {
        "message": "Falta el problema d'allaus, orientacions, estabilitat del mantell de neu, freqüència i/o la mida d'allaus per almenys un problema d'allaus",
        "accept": "D'acord"
      },
      "loadAvActivityCommentExampleTextDialog": {
        "message": "Per quina situació vols carregar un text d'exemple?",
        "newSnow": "Neu recent",
        "windSlab": "Plaques de vent",
        "persistentWeakLayers": "Capes febles persistents",
        "wetSnow": "Neu humida",
        "glidingSnow": "Lliscaments",
        "favourableSituation": "Situació favorable",
        "cancel": "Cancel·lar"
      },
      "removeDaytimeDependencyDialog": {
        "message": "Which avalanche problems do you want to keep?",
        "cancel": "Cancel·lar"
      },
      "strategicMindsetDialog": {
        "title": "Strategic Mindsets",
        "introduction": "The \"Strategic Mindsets\" are intended to express our mental attitude with regard to the avalanche situation and its development in the corresponding warning region. It should not be derived directly from the hazard assessment, but rather understood and used as an intuitive impression of the overall situation. The \"Strategic Mindsets\" are therefore primarily a communication tool. Using the mindsets and associated definitions, we forecasters can quickly and efficiently exchange information about the development of the hazard situation and find a common language for communication.",
        "description": {
          "title": "Description:",
          "text": {
            "assessment": "There is <b>great uncertainty</b> in the hazard assessment due to <b>insufficient information</b> from the field.",
            "stepping_out": "<b>Decreasing tendency</b> of the hazard situation (also within a danger level).",
            "status_quo": "Same hazardous situation. <b>No significant change</b> compared to the previous day.",
            "stepping_back": "<b>Increasing tendency</b> of the hazard situation (also within a danger level).",
            "entrenchment": "<b>Almost constant, tense hazardous situation.</b> The main danger comes from (a) <b>persistent weak layer</b>(s) susceptible to interference.",
            "free_ride": "<b>Low avalanche danger</b> with generally stable snow cover. Only small avalanches are possible in extremely steep terrain. ",
            "high_alert": "Exceptional situation with <b>high or very high avalanche danger</b>. Spontaneous avalanches of <b>very large or extreme magnitude</b> are possible",
            "spring_diurnal": "Wet snow avalanches during the course of the day, which are subject to a <b>clear diurnal cycle</b>, are decisive for the risk assessment."
          }
        },
        "communication": {
          "title": "Communication:",
          "text": {
            "assessment": "Avalanche situation must be checked locally. Restraint is recommended.",
            "stepping_out": "Gradual decrease in danger. However, caution is still required and the improving situation should be assessed locally before entering riskier terrain.  ",
            "status_quo": "Hazardous situation is unchanged. Feedback from observers helps to better outline the hazardous situation. More favourable terrain should be communicated with emphasis. ",
            "stepping_back": "Clear communication of the increase in avalanche danger - even within a danger level. Information on the causes supports comprehensibility in communication. ",
            "entrenchment": "Caution and restraint are required. Situation hardly changes. If possible, provide as precise information as possible on distribution and possibility to trigger. Increased communication of observations that reveal the situation and trigger restraint.",
            "free_ride": "Little text. Emphasise the generally favourable overall situation and potentially isolated hazard areas",
            "high_alert": "Communication across all channels. The focus is no longer on winter sports. Danger even in areas that are not usually at risk of avalanches.",
            "spring_diurnal": "Favourable situation in the morning, increasing during the day. Assessment should be made locally by observing the softening of the melt-freeze crust and the increasing soaking of the snow cover."
          }
        },
        "examples": {
          "title": "Examples:",
          "text": {
            "assessment": "Start of the season.",
            "stepping_out": "Cooling leads to a decrease in the risk of wet snow avalanches after a warm and humid weather phase.",
            "status_quo": "Consistent wind-slab problem with cold temperatures, loose snow and wind.",
            "stepping_back": "Abundant fresh snowfall leads to an increase in the danger situation.",
            "entrenchment": "Critical persistent weak layers problem with potential for dangerously large avalanches. The situation is only stabilising very slowly and requires a great deal of restraint.",
            "free_ride": "Low avalanche danger, no persistent weak layers, all terrain has already been groomed.",
            "high_alert": "Danger level 4 or higher due to large amounts of fresh snow and/or problematic, persistent weak layers. ",
            "spring_diurnal": "Spring situation with dry high-pressure weather and moderate temperatures with otherwise mostly stable old snow cover."
          }
        },
        "strategicMindset": {
          "assessment": "High Uncertainty",
          "stepping_out": "Improving",
          "status_quo": "Status Quo",
          "stepping_back": "Deteriorating",
          "entrenchment": "Persistent Unstable",
          "free_ride": "Favorable",
          "high_alert": "High Alert",
          "spring_diurnal": "Spring Diurnal"
        }
      },
      "decisionTree": {
        "filepath": "./assets/img/decision_tree-en.svg",
        "feedbackURL": "https://admin.avalanche.report/umfrage-entscheidungsbaum/en/",
        "decisionTree": "Arbre de decisions",
        "feedback": "Comentaris",
        "save": "Desar",
        "discard": "Descarta"
      }
    },
    "table": {
      "title": {
        "date": "Data",
        "status": {
          "AT-02": "Caríntia",
          "AT-03": "Baixa Àustria",
          "AT-04": "Alta Àustria",
          "AT-05": "Salzburg",
          "AT-06": "Styria",
          "AT-07": "Tirol",
          "AT-08": "Vorarlberg",
          "CH": "Suïssa",
          "DE-BY": "Bavària",
          "GEOSPHERE": "GEOSPHERE",
          "IT-32-TN": "Trentino",
          "IT-32-BZ": "Tirol del Sud",
          "ES-CT-L": "Aran"
        }
      },
      "status": {
        "missing": "falta",
        "draft": "esborrany",
        "submitted": "enviat",
        "published": "publicat",
        "updated": "actualitzat",
        "resubmitted": "re-enviat",
        "republished": "re-publicat"
      },
      "publishBulletinsDialog": {
        "message": "Vols publicar el butlletí?",
        "missingDangerRating": "Grau de perill no especificat ",
        "missingRegion": "Regions sense graduar",
        "duplicateRegion": "Regions graduades més d'un cop",
        "missingAvActivityHighlights": "Falta el titular de la situació d'allaus",
        "missingAvActivityComment": "Falta la descripció del perill d'allaus",
        "missingSnowpackStructureHighlights": "Falta titular estructura mantell",
        "missingSnowpackStructureComment": "Falta la descripció de l'estructura del mantell",
        "pendingSuggestions": "Suggeriments pendents",
        "incompleteTranslation": "Traducció incompleta",
        "accept": "Si",
        "reject": "No"
      },
      "publicationStatusDialog": {
        "headline": "Estat de la publicació",
        "publishedBy": "Publicat per:",
        "publishedAt": "Publicat a les:",
        "validFor": "Vàlid per:",
        "title": {
          "prod": "Coses serioses",
          "test": "Test system",
          "task": "Tasca",
          "language": {
            "all": "TOTS",
            "de": "DE",
            "it": "IT",
            "en": "EN"
          }
        },

        "email": {
          "label": "Emails",
          "tooltip": "Enviar Emails",
          "success": "{{prefix}}Correus electrònics enviats correctament!",
          "error": "{{prefix}}No s'han pogut enviar els correus electrònics!"
        },
        "telegram": {
          "label": "Missatges Telegram",
          "tooltip": "Enviar missatges de Telegram",
          "success": "{{prefix}}Missatges de Telegram enviats correctament!",
          "error": "{{prefix}}No s'han pogut enviar els missatges de Telegram!"
        },
        "push": {
          "label": "Notificacions push",
          "tooltip": "Enviar notificacions push",
          "success": "{{prefix}}Notificacions push enviades correctament!",
          "error": "{{prefix}}No s'han pogut enviar les notificacions push!"
        },
        "accept": "D'acord"
      },
      "mediaFileDialog": {
        "headline": "Fitxer multimèdia",
        "important": "IMPORTANT",
        "missingFile": "No s'ha seleccionat cap fitxer!",
        "uploadError": "La càrrega ha fallat!",
        "accept": "Enviar",
        "reject": "Cancel·la"
      },
      "publishBulletinsErrorDialog": {
        "message": "Hi ha hagut un error publicant els butlletins!",
        "accept": "D'acord"
      },
      "submitBulletinsDialog": {
        "message": "Vols enviar el butlletí?",
        "missingDangerRating": "Grau de perill no especificat ",
        "missingRegion": "Regions sense graduar",
        "missingAvActivityHighlights": "Falta el titular de la situació d'allaus",
        "missingAvActivityComment": "Falta la descripció del perill d'allaus",
        "missingSnowpackStructureHighlights": "Falta titular estructura mantell",
        "missingSnowpackStructureComment": "Falta la descripció de l'estructura del mantell",
        "pendingSuggestions": "Suggeriments pendents",
        "accept": "Si",
        "reject": "No"
      },
      "submitBulletinsErrorDialog": {
        "message": "Hi ha hagut un error pujant els butlletins!",
        "accept": "D'acord"
      },
      "submitBulletinsDuplicateRegionDialog": {
        "message": "Regions graduades més d'un cop! El butlletí no es pot pujar",
        "accept": "D'acord"
      },
      "checkBulletinsErrorDialog": {
        "message": "Hi ha hagut un error comprovant els butlletins!",
        "accept": "D'acord"
      },
      "previewErrorDialog": {
        "message": "No s'ha pogut carregar la previsualització.",
        "accept": "D'acord"
      },
      "checkBulletinsDialog": {
        "message": "Comprovar el butlletí",
        "missingDangerRating": "Grau de perill no especificat ",
        "missingRegion": "Regions sense graduar",
        "missingAvActivityHighlights": "Falta el titular de la situació d'allaus",
        "missingAvActivityComment": "Falta la descripció del perill d'allaus",
        "missingSnowpackStructureHighlights": "Falta titular estructura mantell",
        "missingSnowpackStructureComment": "Falta la descripció de l'estructura del mantell",
        "pendingSuggestions": "Suggeriments pendents",
        "incompleteTranslation": "Traducció incompleta",
        "ok": "(tot correcte)",
        "accept": "D'acord"
      },
      "publishAllDialog": {
        "message": "Vols publicar totes les regions?",
        "accept": "Si",
        "reject": "No"
      }
    }
  },
  "training": {
    "training": "Training",
    "timestamp": "Training timestamp"
  },
  "admin": {
    "loadingStatistics": "Creant el CSV ...",
    "server-configuration": {
      "title": "Servidor",
      "button": {
        "create": "Crear servidor",
        "save": "Desar"
      },
      "table": {
        "name": "Nom",
        "username": "Nom d'usuari",
        "password": "Contrasenya",
        "publishAt5PM": "Publica a les 5 PM",
        "publishAt8AM": "Publica a les 8 AM",
        "pdfDirectory": "Directori per PDF",
        "htmlDirectory": "Directori per HTML",
        "serverImagesUrl": "Adreça de les imatges del servidor",
        "mapsPath": "Adreça dels mapes",
        "mediaPath": "Ruta dels fitxers multimèdia",
        "mapProductionUrl": "URL per la producció dels mapes",
        "apiUrl": "API URL"
      },
      "success": "Configuració guardada satisfactòriament",
      "error": "No es pot guardar la contrasenya"
    },
    "region-configuration": {
      "title": "Regió",
      "button": {
        "create": "Crear regió",
        "save": "Desar"
      },
      "table": {
        "general": {
          "title": "General",
          "id": "ID",
          "microRegions": "Nombre de micro-regions",
          "subRegions": "Sub-regions",
          "superRegions": "Super-regions",
          "neighborRegions": "Regions veïnes"
        },
        "publication": {
          "title": "Publication",
          "publishBulletins": "Publicar la predicció d'allaus",
          "publishBlogs": "Publicar entrades al blog",
          "createCaamlV5": "Crear CAAML v5",
          "createCaamlV6": "Crear CAAML v6",
          "createJson": "Crear JSON",
          "createMaps": "Crear mapes",
          "createPdf": "Crear PDF",
          "sendEmails": "Enviar correus",
          "createSimpleHtml": "Crear HTML simple",
          "sendTelegramMessages": "Enviar missatges de Telegram",
          "sendPushNotifications": "Enviar notificacions push",
          "pdfColor": "PDF color",
          "emailColor": "Email color",
          "pdfMapYAmPm": "Y per al mapa PDF (am/pm)",
          "pdfMapYFd": "Y per al mapa PDF (fd)",
          "pdfMapWidthAmPm": "Amplada del mapa per a PDF (am/pm)",
          "pdfMapWidthFd": "Amplada del mapa per a PDF (fd)",
          "pdfMapHeight": "Alçada del mapa per a PDF",
          "pdfFooterLogo": "Logotip per al peu de pàgina de PDF",
          "pdfFooterLogoColorPath": "Logotip per al peu de pàgina de PDF (color)",
          "pdfFooterLogoBwPath": "Logotip per al peu de pàgina de PDF (bw)",
          "mapXmax": "X del mapa màxim",
          "mapXmin": "X del mapa mínim",
          "mapYmax": "Y del mapa màxim",
          "mapYmin": "Y del mapa mínim",
          "simpleHtmlTemplateName": "Plantilla HTML senzilla",
          "geoDataDirectory": "Directori de geodades",
          "mapLogoColorPath": "Logotip per al mapa (color)",
          "mapLogoBwPath": "Logotip per al mapa (bw)",
          "mapLogoPosition": "Posició del logotip per al mapa",
          "mapCenterLat": "Centrar posició del mapa (latitud)",
          "mapCenterLng": "Posició del logotip per al mapa (longitud)",
          "imageColorbarColorPath": "Barra de colors (color)",
          "imageColorbarBwPath": "Barra de colors (b/w)"
        },
        "components": {
          "title": "Components",
          "enableDangerSources": "Enable danger sources",
          "enableObservations": "Enable observations",
          "enableModelling": "Enable modelling",
          "enableWeatherbox": "Enable weather box"
        },
        "configuration": {
          "title": "Configuration",
          "showMatrix": "Mostrar la matriu",
          "enableMediaFile": "Activar el fitxer multimèdia",
          "enableStrategicMindset": "Enable strategic mindset",
          "enableStressLevel": "Enable stress level",
          "enableAvalancheProblemCornices": "Habilitar el problema d'allaus CORNISES",
          "enableAvalancheProblemNoDistinctAvalancheProblem": "Habilitar el problema d'allaus CAP PROBLEMA D'ALLAUS EVIDENT"
        }
      },
      "success": "Configuració guardada satisfactòriament",
      "error": "No es pot guardar la contrasenya"
    },
    "users": {
      "title": "Usuaris",
      "button": {
        "create": " Crear usuari"
      },
      "tooltip": {
        "edit": "Editar usuari",
        "changePassword": "Canviar la contrasenya",
        "delete": "Esborrar usuari"
      },
      "table": {
        "name": "Nom",
        "email": "Email",
        "organization": "Organizació",
        "regions": "Regions",
        "roles": "Funcions"
      },
      "deleteUserModalDialog": {
        "message": "Voleu eliminar l’usuari?",
        "accept": "Si",
        "reject": "No"
      },
      "deleteUser": {
        "success": "S'ha suprimit l'usuari",
        "error": "No s'ha pogut suprimir l'usuari"
      },
      "createUser": {
        "success": "Usuari creat",
        "error": "No s'ha pogut crear l'usuari"
      },
      "updateUser": {
        "success": "Usuari actualitzat",
        "error": "L'usuari no s'ha pogut actualitzar"
      }
    },
    "createUser": {
      "table": {
        "image": "Image",
        "name": "Nom",
        "email": "Email",
        "organization": "Organizació",
        "password": "Contrasenya",
        "password2": "Repetir contrasenya",
        "roles": "Funcions",
        "regions": "Regions"
      },
      "error": {
        "name": {
          "required": "Introduir un nom"
        },
        "email": {
          "required": "Introduir una adreça de correu electrònic",
          "invalid": "L'adreça de correu electrònic no és vàlida"
        },
        "password": {
          "required": "Introduir una contrasenya",
          "length": "La contrasenya ha de tenir com a mínim 8 caràcters",
          "mismatch": "Les contrasenyes no coincideixen"
        }
      },
      "button": {
        "create": "Crear usuari",
        "update": "Actualitzar l'usuari",
        "cancel": "Cancel·lar"
      }
    },
    "observations": {
      "table": "Taula",
      "map": "Mapa",
      "gallery": "Gallery",
      "filters": "Filtres",
      "title": "Observacions",
      "newObservation": "New observation",
      "exportObservations": "Export observations",
      "toggleFilterBar": "Show/hide filter bar",
      "button": {
        "submit": "Descarregar"
      },
      "label": {
        "dateRange": "Període",
        "observer": "Observador"
      },
      "selectRegion": {
        "placeholder": "Regió",
        "selectionLabel": "{0} region(s) selected"
      },
      "selectSources": {
        "placeholder": "Fonts",
        "selectionLabel": "{0} source(s) selected"
      },
      "charts": {
        "charts": "Gràfics",
        "nan": "Sense especificar",
        "classify": "Classificar",
        "label": "Etiqueta",
        "invert": "Invertir",
        "reset": "Restablir",
        "aspect": {
          "caption": "Orientació"
        },
        "elevation": {
          "caption": "Altitud"
        },
        "stability": {
          "caption": "Estabilitat"
        },
        "observationType": {
          "caption": "Tipus d'observació"
        },
        "importantObservation": {
          "caption": "Important"
        },
        "avalancheProblem": {
          "caption": "Problema d'Allaus"
        },
        "dangerPattern": {
          "caption": "Patró de perill"
        },
        "days": {
          "caption": "Dia"
        }
      }
    },
    "blog": {
      "title": "Blog",
      "language": {
        "title": "Idioma",
        "de": "DE",
        "it": "IT",
        "en": "EN"
      },
      "all": {
        "tooltip": "Enviar correus electrònics, Telegram i Push",
        "success": "{{prefix}}Correu electrònic, Telegram i Push enviats correctament!",
        "error": "{{prefix}}No s'han pogut enviar el correu electrònic, Telegram i Push!"
      },
      "email": {
        "tooltip": "Enviar Emails",
        "success": "{{prefix}}Correu electrònic enviat correctament!",
        "error": "{{prefix}}No s'ha pogut enviar el correu electrònic!"
      },
      "telegram": {
        "tooltip": "Enviar a través del canal de Telegram",
        "success": "{{prefix}}Telegram enviat correctament!",
        "error": "{{prefix}}No s'ha pogut enviar el Telegram!"
      },
      "push": {
        "tooltip": "Enviar mitjançant notificacions push",
        "success": "{{prefix}}Push enviat correctament!",
        "error": "{{prefix}}No s'ha pogut enviar el push!"
      }
    }
  },
  "settings": {
    "title": "Configuració",
    "changePassword": {
      "title": "Canviar la contrasenya",
      "oldPassword": {
        "label": "Contrasenya actual",
        "placeholder": "Contrasenya actual"
      },
      "newPassword1": {
        "label": "Nova contrasenya",
        "placeholder": "Nova contrasenya"
      },
      "newPassword2": {
        "label": "Contrasenya nova (repetir)",
        "placeholder": "Nova contrasenya"
      },
      "submit": "Canviar la contrasenya",
      "passwordChanged": "Contrasenya canviada",
      "passwordChangeError": "No es pot canviar la contraenya",
      "passwordIncorrect": "La contrasenya actual és incorrecta",
      "error": {
        "oldPassword": {
          "required": "Si us plau introdueix la contrasenya actual"
        },
        "newPassword": {
          "required": "Si us plau introdueix la nova contrasenya",
          "mismatch": "Les contrasenyes no coincideixen"
        }
      }
    },
    "display": {
      "title": "Visualitzar",
      "table": {
        "showCaaml": "Mostrar CAAML",
        "showJson": "Mostrar JSON"
      }
    }
  },
  "statistics": {
    "title": "Estadístiques",
    "button": {
      "submit": "Descarregar"
    },
    "label": {
      "dateRange": "Període",
      "extended": "Ampliat",
      "duplicates": "Duplicats"
    }
  },

  "dangerRating": {
    "noRating": "Sense grau de perill",
    "low": "Feble",
    "moderate": "Moderat",
    "considerable": "Marcat",
    "high": "Fort",
    "veryHigh": "Molt fort"
  },
  "avalancheProblem": {
    "newSnow": "Neu recent",
    "new_snow": "Neu recent",
    "windSlab": "Plaques de vent",
    "wind_slab": "Plaques de vent",
    "persistentWeakLayers": "Capes febles persistents",
    "persistent_weak_layers": "Capes febles persistents",
    "wetSnow": "Neu humida",
    "wet_snow": "Neu humida",
    "glidingSnow": "Lliscaments",
    "gliding_snow": "Lliscaments",
    "cornices": "Cornises",
    "noDistinctAvalancheProblem": "No distinct avalanche problem",
    "no_distinct_avalanche_problem": "No distinct avalanche problem",
    "favourableSituation": "Situació favorable",
    "favourable_situation": "Situació favorable"
  },
  "dangerPattern": {
    "dp1": "pp.1: capa feble persistent profunda",
    "dp2": "pp.2: allaus de lliscament",
    "dp3": "pp.3: pluja",
    "dp4": "pp.4: fred després de temperat / temperat després de fred",
    "dp5": "pp.5: nevada després d'un llarg període fred",
    "dp6": "pp.6: neu freda i solta i vent",
    "dp7": "dp.7: shallow snow next to deep snow",
    "dp8": "pp.8: gebre enterrat",
    "dp9": "pp.9: neu rodona enterrada",
    "dp10": "pp.10: escenari de primavera"
  },
  "strategicMindset": {
    "title": "Strategic Mindset",
    "assessment": "High Uncertainty",
    "stepping_out": "Improving",
    "status_quo": "Status Quo",
    "stepping_back": "Deteriorating",
    "entrenchment": "Persistent Unstable",
    "free_ride": "Favorable",
    "high_alert": "High Alert",
    "spring_diurnal": "Spring Diurnal"
  },
  "observationType": {
    "TimeSeries": "Sèrie temporal",
    "Incident": "Incident",
    "Profile": "Perfil de neu",
    "Closure": "Tancament",
    "Blasting": "Voladura",
    "Avalanche": "Allau",
    "Evaluation": "Avaluació",
    "SimpleObservation": "Observació simple",
    "Webcam": "Webcam"
  },
  "importantObservation": {
    "ForBlog": "For blog",
    "SnowLine": "Límit de neu",
    "SurfaceHoar": "Gebre de superfície",
    "Graupel": "Neu granulada",
    "StabilityTest": "Test d'estabilitat",
    "IceFormation": "Formació de gel",
    "VeryLightNewSnow": "Neu recent molt lleugera"
  },
  "aspect": {
    "N": "N",
    "NE": "NE",
    "E": "E",
    "SE": "SE",
    "S": "S",
    "SW": "SW",
    "W": "W",
    "NW": "NW"
  },
  "role": {
    "SUPERADMIN": "Super Administrator",
    "ADMIN": "Administrador",
    "FORECASTER": "Predictor",
    "FOREMAN": "Mestre d'obres",
    "OBSERVER": "Observador"
  },
  "tendency": {
    "decreasing": "El perill d'allaus disminueix",
    "steady": "El perill d'allaus roman estable",
    "increasing": "El perill d'allaus augmenta"
  },
  "snowpackStability": {
    "unknown": "desconegut",
    "good": "bona",
    "fair": "regular",
    "poor": "pobre",
    "very_poor": "molt pobre"
  },
  "frequency": {
    "none": "(gairebé) cap",
    "few": "unes poques",
    "some": "algunes",
    "many": "moltes"
  },
  "avalancheSize": {
    "small": "petita",
    "medium": "mitjana",
    "large": "gran",
    "very_large": "molt gran",
    "extreme": "extremadament gran"
  },
  "complexity": {
    "easy": "Fàcil",
    "challenging": "Exigent",
    "complex": "Complex"
  },
  "glidingSnowActivity": {
    "low": "Feble",
    "medium": "mitjana",
    "high": "fort"
  },
  "wetness": {
    "dry": "dry",
    "moist": "moist",
    "wet": "wet"
  },
  "characteristic": {
    "low": "Feble",
    "medium": "mitjana",
    "high": "fort",
    "very_high": "molt fort"
  },
  "thickness": {
    "thin": "thin",
    "thick": "thick"
  },
  "snowpackPosition": {
    "upper": "upper",
    "middle": "middle",
    "lower": "lower",
    "ground": "ground"
  },
  "creationProcess": {
    "radiation_recrystallization": "radiació",
    "diurnal_recrystallization": "diurnal",
    "melt_layer_recrystallization": "melt layer"
  },
  "recognizability": {
    "very_easy": "very easy",
    "easy": "easy",
    "hard": "hard",
    "very_hard": "very hard"
  },
  "distribution": {
    "isolated": "isolated",
    "specific": "specific",
    "widespread": "widespread"
  },
  "probability": {
    "likely": "likely",
    "possible": "possible",
    "unlikely": "unlikely"
  },
  "detailedDaytime": {
    "evening": "evening",
    "first_night_half": "first night half",
    "second_night_half": "second night half",
    "morning": "morning",
    "forenoon": "forenoon",
    "afternoon": "afternoon"
  },
  "slopeGradient": {
    "moderately_steep": "moderately steep",
    "steep": "steep",
    "very_steep": "very steep",
    "extremely_steep": "extremely steep"
  },
  "handHardness": {
    "fist": "F",
    "four_fingers": "4F",
    "one_finger": "1F",
    "pencil": "P",
    "knife": "K",
    "ice": "I"
  },
  "hardnessTendency": {
    "decreasing": "softer below",
    "steady": "uniform",
    "increasing": "softer above"
  },
  "terrainType": {
    "gullies": "gullies",
    "bowls": "bowls",
    "pass_areas": "pass areas",
    "ridge_lines": "ridge lines"
  },
  "dangerSign": {
    "shooting_cracks": "shooting cracks",
    "whumpfing": "whumpfing"
  },
  "changeRegionDialog": {
    "message": "Vols canviar de regió?",
    "accept": "Si",
    "reject": "No"
  },
  "zamg": {
    "table": "Taula",
    "map": "Mapa",
    "region": "Regió",
    "newSnow": "Neu recent"
  },
  "snowpack": {
    "plotType": {
      "LWC_stratigraphy": "Neu humida: contingut d'aigua líquida",
      "wet_snow_instability": "Neu humida: Inestabilitat de neu humida",
      "Sk38_stratigraphy": "Capes febles persistents: Índex d'estabilitat SK38",
      "stratigraphy": "Capes febles persistents: estratigrafia de la neu"
    },
    "aspect": {
      "flat": "pla",
      "north": "nord",
      "south": "sud"
    },
    "meteo": {
      "new_snow_plot_3day": "neu nova (3d)",
      "new_snow_plot_7day": "neu nova (7d)",
      "new_snow_plot_1month": "neu nova (31d)",
      "new_snow_plot_season": "neu nova (temporada)",
      "new_snow_plot_forecast": "neu nova (previsió)",
      "wet_snow_plot_3day": "neu humida (3d)",
      "wet_snow_plot_7day": "Neu humida (7d)",
      "wet_snow_plot_1month": "neu humida (31d)",
      "wet_snow_plot_season": "Neu humida (temporada)",
      "wet_snow_plot_forecast": "Neu humida (previsió)",
      "HS_table_24h": "taula d'alçada de la neu (1d)",
      "HS_table_72h": "taula d'alçada de la neu (3d)",
      "HS_table_season": "taula d'alçada de la neu (temporada)",
      "HS_table_forecast": "taula d'alçada de la neu (previsió)",
      "TA_table_24h": "taula de temperatures (1d)",
      "TA_table_72h": "taula de temperatures (3h)",
      "TA_table_season": "taula de temperatures (temporada)"
    }
  },
  "grainShape": {
    "PP": {
      "code": "PP",
      "class": "Precipitation Particles"
    },
    "PPgp": {
      "code": "PPgp",
      "class": "Neu granulada"
    },
    "DF": {
      "code": "DF",
      "class": "Decomposing and Fragmented precipitation particles"
    },
    "RG": {
      "code": "RG",
      "class": "Rounded Grains"
    },
    "FC": {
      "code": "FC",
      "class": "Faceted Crystals"
    },
    "DH": {
      "code": "DH",
      "class": "Depth Hoar"
    },
    "SH": {
      "code": "SH",
      "class": "Surface Hoar"
    },
    "MF": {
      "code": "MF",
      "class": "Melt Forms"
    },
    "MFcr": {
      "code": "MFcr",
      "class": "Melt-freeze crust"
    },
    "IF": {
      "code": "IF",
      "class": "Ice Formations"
    }
  },
  "button": {
    "yes": "yes",
    "no": "no"
  },
  "observations": {
    "allowEdit": "Edit external observation",
    "allowEditTooltip": "This will prevent overwrites from {{ value }}",
    "editIconTooltip": "Edited observation - receives no updates from {{ value }}",
    "aspect": "Orientació",
    "authorName": "Nom de l'autor",
    "avalancheLength": "Longitud d'allau",
    "avalancheProblem": "Problema d'allaus",
    "avalancheSize": "Mida d'allau",
    "avalancheType": "Tipus d'allau",
    "avalancheWidth": "Amplada d'allau",
    "search": "Cerca",
    "count": "# observacions:",
    "layers": {
      "observers": "Observers",
      "weatherStations": "Weather stations",
      "webcams": "Webcams",
      "observations": "Observacions"
    },
    "weatherStations": {
      "tooltips": {
        "globalRadiation": "Global radiation",
        "newSnow": "Neu recent",
        "snowLine": "Límit de neu",
        "snowHeight": "Snow height",
        "snowDifference24h": "Snow difference 24h",
        "snowDifference48h": "Snow difference 48h",
        "snowDifference72h": "Snow difference 72h",
        "airTemperature": "Air temperature",
        "airTemperatureMax": "Air temperature Max",
        "airTemperatureMin": "Air temperature Min",
        "surfaceTemperature": "Surface temperature",
        "surfaceHoar": "Potential surface hoar formation",
        "surfaceHoarCalc": "Potential surface hoar formation (Lehning et. al.)",
        "dewPoint": "Dew point temperature",
        "relativeHumidity": "Relative humidity",
        "windDirection": "Wind direction",
        "windSpeed": "Wind speed",
        "windGust": "Wind gust"
      }
    },
    "button": {
      "add": "Afegir observació",
      "save": "Guardar observació",
      "delete": "Eliminar observació",
      "deleteConfirm": "Eliminar observació?",
      "discard": "Descarta",
      "copyDate": "Copy event date"
    },
    "comment": "Comentari",
    "content": "Contingut",
    "dangerRating": "Grau de perill",
    "elevation": "Altitud",
    "elevationRange": "Elevation range",
    "elevationLowerBound": "Elevation lower bound",
    "elevationUpperBound": "Elevation upper bound",
    "eventDate": "Data d'esdeveniment",
    "eventType": "Tipus d'esdeveniment",
    "eventTypes": {
      "IMPORTANT": "Esdeveniment important",
      "NEIGHBOR_REGION": "Regió veïna",
      "NORMAL": "Esdeveniment normal",
      "PERSON_DEAD": "Esdeveniment amb persones implicades (mortes)",
      "PERSON_INJURED": "Esdeveniment amb persones implicades (ferides)",
      "PERSON_NO": "Esdeveniment sense persones implicades",
      "PERSON_UNINJURED": "Esdeveniment amb persones implicades (il·leses)",
      "PERSON_UNKNOWN": "Esdeveniment (desconegut)",
      "TRAFFIC": "Trànsit"
    },
    "externalURL": "External URL (e.g. to photo database)",
    "fractureDepth": "Profunditat cicatriu",
    "hasCoordinates": "Té coordenades",
    "incline": "Pendent",
    "latitude": "Laltitud",
    "locationName": "Localitat",
    "longitude": "Longitud",
    "observationType": "Observation type",
    "personInvolvement": "Person involvement",
    "reportDate": "Data de l'informe",
    "showTable": "Taula",
    "withoutCoordinates": "Observacions sense coordenades:",
    "sidebar": {
      "filter": {
        "title": "Filtre"
      },
      "legend": {
        "title": "Llegenda"
      },
      "sources": {
        "title": "Fonts de dades"
      },
      "views": {
        "title": "Vistes"
      }
    }
  },
  "personInvolvement": {
    "Dead": "Esdeveniment amb persones implicades (mortes)",
    "Injured": "Esdeveniment amb persones implicades (ferides)",
    "No": "Esdeveniment sense persones implicades",
    "Uninjured": "Esdeveniment amb persones implicades (il·leses)",
    "Unknown": "Esdeveniment (desconegut)"
  },
  "units": {
    "cm": "cm",
    "mm": "mm",
    "m": "m"
  },
  "qfa": {
    "day": "Dia",
    "time": "Hora",
    "back": "Enrera",
    "parameters": {
      "DUST": "pols sahariana",
      "N": "Cobertura de núvols total en /8",
      "Nh": "Cobertura de núvols alts en /8",
      "Nm": "Cobertura de núvols mitjans en /8",
      "Nl": "Cobertura de núvols baixos en /8",
      "N-CU": "Cobertura de núvols cumulus en /8",
      "AUSL.T": "temperatura desencadenant",
      "SHW-IX": "Index Showalter",
      "WX -CUF": "Temps significatiu: NIL (cap), RASH (pluja), TS (tempesta)",
      "RR": "precipitaió total [mm]",
      "RR-str": "precipitatió estratiforme",
      "CONV": "precipitació convectiva [mm]",
      "SN": "precipitació en forma de neu [mm]",
      "SN--RA": "límit de la neu [m]",
      "QAO ": "{{value}} hPa eq. vent mitjà, direcció i velocitat, [°] i [m/s]",
      "QAN": "vent mitjà al terra, direcció i velocitat, [º] i [m/s]",
      "QANmax": "vent màx. (ràfagues) al terra [m/s]",
      "T ": "{{value}} hPa eq. temperatura [°C]",
      "T m": "temperatura a {{value}}m [°C]",
      "T 2m": "temperatura a 2m [°C]",
      "Tk 2m": "temperatura a 2m [°K]",
      "T GND": "temperatura al terra",
      "Max-ADI": "temperatura màxima de barreja [°C]",
      "Min Max": "temperatura mín./màx. en les darreres 24h",
      "FROST": "alçada isozero [m]",
      "W cm": "500 hPa eq. vent vertical [cm/s]",
      "W --": "700 hPa eq. vent vertical [cm/s]",
      "W s": "{{value}} hPa eq. vent vertical [cm/s]",
      "H ": "{{value}} hPa alçada geopotencial [m]",
      "RF ": "{{value}} hPa eq. humitat relativa [%]"
    }
  }
}
